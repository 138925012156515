import { refreshPage } from '../../helpers/commonHelper';
import warningIcon from '../../assets/icons/warning.svg';
import { memo } from 'react';

const RefreshNotification = memo(({ message, testId }) => {
  return (
    <div data-testid={testId} className="text-xs inline-flex items-center">
      <img
        className="w-[14px] h-[14px] mr-2 inline-block align-middle"
        src={warningIcon}
        alt="warning-icon"
      />
      <span className="inline">
        {message}
        <span
          data-testid="refresh-link"
          onClick={refreshPage}
          className="underline cursor-pointer text-purple-vs ml-1"
        >
          Refresh.
        </span>
      </span>
    </div>
  );
});

export default RefreshNotification;
