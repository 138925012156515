import { memo } from 'react';
import BatchActionsModal from '../mapping/modals/BatchActionsModal';
import AddCodeTagsForm from '../mapping/modals/AddCodeTagsForm';
import {
  ADD_CODE_TAGS_MODAL,
  REMOVE_CODE_TAGS_MODAL,
  REPLACE_CODE_TAGS_MODAL,
  UPDATE_FREE_DOSES_MODAL,
  UPDATE_PAID_DOSES_MODAL,
  UPDATE_REVENUE_CATEGORY_MODAL,
  UPDATE_REVIEW_STATUS_MODAL,
  UPDATE_VERIFICATION_STATUS_MODAL,
} from '../../constants/modals';
import ReplaceCodeTagsForm from '../mapping/modals/ReplaceCodeTagsForm';
import RemoveCodeTagsForm from '../mapping/modals/RemoveCodeTagsForm';
import UpdateRevenueCategoryForm from '../mapping/modals/UpdateRevenueCategoryForm';
import UpdateDosesForm from '../mapping/modals/UpdateDosesForm';
import UpdateReviewStatusForm from '../mapping/modals/UpdateReviewStatusForm';
import UpdateVerificationStatusForm from '../mapping/modals/UpdateVerificationStatusForm';
import { useSelector } from 'react-redux';

const BatchActionsModals = memo(function BatchActionsModals({
  onDialogCancel,
  addCodeTagsHandler,
  removeCodeTagsHandler,
  replaceCodeTagsHandler,
  updateRevenueCategoryHandler,
  updateReviewStatusHandler,
  updateFreeDosesHandler,
  updatePaidDosesHandler,
  updateVerificationStatusHandler,
  filtersPanelOpen,
  availableCodeTags,
}) {
  const modal = useSelector((state) => state.modal);
  return (
    <>
      <BatchActionsModal
        title="Add code tags to selected"
        onClose={onDialogCancel}
        form={
          <AddCodeTagsForm
            onCancel={onDialogCancel}
            onSubmitHandler={addCodeTagsHandler}
            availableCodeTags={availableCodeTags}
          />
        }
        isOpen={modal.visibleModal === ADD_CODE_TAGS_MODAL}
        filtersPanelOpen={filtersPanelOpen}
      />
      <BatchActionsModal
        title="Replace code tags for selected"
        onClose={onDialogCancel}
        form={
          <ReplaceCodeTagsForm
            onCancel={onDialogCancel}
            onSubmitHandler={replaceCodeTagsHandler}
            availableCodeTags={availableCodeTags}
          />
        }
        isOpen={modal.visibleModal === REPLACE_CODE_TAGS_MODAL}
        filtersPanelOpen={filtersPanelOpen}
      />
      <BatchActionsModal
        onClose={onDialogCancel}
        title="Remove code tags"
        form={
          <RemoveCodeTagsForm
            onCancel={onDialogCancel}
            onSubmitHandler={removeCodeTagsHandler}
            availableCodeTags={availableCodeTags}
          />
        }
        isOpen={modal.visibleModal === REMOVE_CODE_TAGS_MODAL}
        filtersPanelOpen={filtersPanelOpen}
      />
      <BatchActionsModal
        onClose={onDialogCancel}
        title="Update revenue category"
        form={
          <UpdateRevenueCategoryForm
            onCancel={onDialogCancel}
            onSubmitHandler={updateRevenueCategoryHandler}
          />
        }
        isOpen={modal.visibleModal === UPDATE_REVENUE_CATEGORY_MODAL}
        filtersPanelOpen={filtersPanelOpen}
      />
      <BatchActionsModal
        onClose={onDialogCancel}
        title="Update free doses"
        form={
          <UpdateDosesForm
            onCancel={onDialogCancel}
            dosesType="free_doses"
            onSubmitHandler={updateFreeDosesHandler}
          />
        }
        isOpen={modal.visibleModal === UPDATE_FREE_DOSES_MODAL}
        filtersPanelOpen={filtersPanelOpen}
      />
      <BatchActionsModal
        onClose={onDialogCancel}
        title="Update paid doses"
        form={
          <UpdateDosesForm
            onCancel={onDialogCancel}
            dosesType="paid_doses"
            onSubmitHandler={updatePaidDosesHandler}
          />
        }
        isOpen={modal.visibleModal === UPDATE_PAID_DOSES_MODAL}
        filtersPanelOpen={filtersPanelOpen}
      />
      <BatchActionsModal
        onClose={onDialogCancel}
        title="Update review status"
        form={
          <UpdateReviewStatusForm
            onCancel={onDialogCancel}
            onSubmitHandler={updateReviewStatusHandler}
          />
        }
        isOpen={modal.visibleModal === UPDATE_REVIEW_STATUS_MODAL}
        filtersPanelOpen={filtersPanelOpen}
      />
      <BatchActionsModal
        onClose={onDialogCancel}
        title="Update verification status"
        form={
          <UpdateVerificationStatusForm
            onCancel={onDialogCancel}
            onSubmitHandler={updateVerificationStatusHandler}
          />
        }
        isOpen={modal.visibleModal === UPDATE_VERIFICATION_STATUS_MODAL}
        filtersPanelOpen={filtersPanelOpen}
      />
    </>
  );
});

export default BatchActionsModals;
