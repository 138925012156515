import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createPipelineStatus,
  deletePipelineStatus,
  getPipelineStatuses,
  updatePipelineStatus,
} from '../../services/pipelineStatusServices';
import { parseError } from '../../helpers/errorHelper';

export const loadPipelineStatuses = createAsyncThunk(
  'table/getPipelineStatuses',
  async (arg, { rejectWithValue }) => {
    try {
      return (await getPipelineStatuses()).data.sort((a, b) =>
        b.updated_at.localeCompare(a.updated_at)
      );
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const addPipelineStatus = createAsyncThunk(
  'table/addPipelineStatuses',
  async (data, { rejectWithValue }) => {
    try {
      const res = await createPipelineStatus(data);
      return res.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const editPipelineStatus = createAsyncThunk(
  'table/editPipelineStatuses',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await updatePipelineStatus(id, data);
      return res.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const removePipelineStatus = createAsyncThunk(
  'table/deletePipelineStatuses',
  async (id, { rejectWithValue }) => {
    try {
      await deletePipelineStatus(id);
      return id;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);
