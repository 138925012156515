export default function JobActionPanel() {
  return (
    <div className="flex mx-4" id="status-container">
      <div className="flex flex-grow my-4 pr-1 h-[30px]  items-center">
        <div className="flex w-3/12  pr-4 " id="actions-dropdown-placeholder" />
        <div
          className="flex w-3/12  pr-4 justify-start"
          id="notification-placeholder"
        />
        <div className="flex w-4/12  pr-4 " id="protocol-placeholder" />
        <div
          className="w-2/12 flex justify-end"
          id="undo-section-placeholder"
        />
      </div>
      <div
        className="flex justify-end items-center w-[250px] mr-[30px]"
        id="right-section-placeholder"
      />
    </div>
  );
}
