import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseError } from '../../helpers/errorHelper';
import { getGroups } from '../../services/mappingDataServices';

export const loadFullGroups = createAsyncThunk(
  'groups/getFullGroups',
  async (arg, { rejectWithValue }) => {
    try {
      return (await getGroups()).data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);
