import { useState } from 'react';
import { ErrorContext } from './ErrorContext';
import { parseError } from './helpers/errorHelper';

export const ErrorProvider = ({ children }) => {
  const [errorAlert, setContextError] = useState(null);

  const setErrorAlert = ({
    error,
    parsedError = null,
    onButtonClick = null,
    onClose = null,
    buttonText = null,
    closingButton = false,
    ctaButton = false,
  }) => {
    let alert = error ? parseError(error) : parsedError;
    setContextError({
      error: alert,
      onButtonClick: () => {
        if (onButtonClick) onButtonClick();
        setContextError(null);
      },
      onClose: () => {
        if (onClose) onClose();
        setContextError(null);
      },
      buttonText,
      closingButton,
      ctaButton,
    });
  };

  const resetErrorAlert = () => {
    setContextError(null);
  };

  return (
    <ErrorContext.Provider
      value={{ errorAlert, setErrorAlert, resetErrorAlert }}
    >
      {children}
    </ErrorContext.Provider>
  );
};
