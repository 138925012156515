import closeIcon from '../../assets/icons/close.svg';
import warningIcon from '../../assets/icons/warning.svg';

const ToasterNotification = ({ actionCallback, dismissCallback }) => {
  return (
    <div
      data-testid="toaster-notification"
      className="fixed bottom-4 right-4 bg-toaster-background border border-toaster-border w-[364px] h-[102px] rounded p-4 shadow-lg z-50"
    >
      <div className="flex justify-between">
        <img
          className="w-[20px] h-[20px] "
          src={warningIcon}
          alt="warning-icon"
        />
        <div className="flex flex-col ml-4">
          <p className="text-sm font-semibold mb-2">Database Updated</p>
          <p className="text-xs">
            Please{' '}
            <span
              data-testid="toaster-notification-refresh-link"
              onClick={actionCallback}
              className="underline cursor-pointer text-purple-vs text-center"
            >
              refresh
            </span>{' '}
            the page. There may be new mappings in this job.
          </p>
        </div>
        <img
          className="w-[20px] h-[20px] cursor-pointer"
          src={closeIcon}
          alt="close-icon"
          onClick={dismissCallback}
        />
      </div>
    </div>
  );
};

export default ToasterNotification;
