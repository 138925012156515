import { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { createFilter } from 'react-select';
import { WindowedMenuList } from 'react-windowed-select';
import { digitRgx } from '../../../constants/constants';

export const CustomAsyncMultiSelect = ({ options, value, onValueChange }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const filterOptions = (inputValue) => {
    const isValid =
      inputValue && (inputValue.match(digitRgx) || inputValue.length >= 3);
    return isValid
      ? options.filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        )
      : [];
  };

  const loadOptions = (inputValue, callback) => {
    callback(filterOptions(inputValue));
  };

  return (
    <AsyncSelect
      defaultOptions
      cacheOptions
      loadOptions={loadOptions}
      components={{ MenuList: WindowedMenuList }}
      value={value}
      onChange={onValueChange}
      filterOption={createFilter({ ignoreAccents: false })}
      isMulti
      menuPosition="fixed"
      isClearable={false}
      noOptionsMessage={() => null}
      onMenuOpen={() => setIsMenuOpen(true)}
      onMenuClose={() => setIsMenuOpen(false)}
      onKeyDown={(e) => isMenuOpen && e.stopPropagation()}
      className="w-full p-2.5"
    />
  );
};
