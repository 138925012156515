import moment from 'moment/moment';
import {
  INVOICABLE_JOB_STATUSES,
  JOB_STATUS_IN_PROGRESS,
  JOB_STATUS_MAPPING_COMPLETE,
  JOB_TYPES,
  JOBS_CSV_EXPORT_COLUMNS,
  jobTypes,
  mappableJobStatuses,
  viewableJobStatuses,
} from '../constants/jobConstants';
import { isAdmin, isSuperAdmin } from './userHelper';

export function generateMappingJobTitle(mappingJobDetails) {
  let title = `Job Mappings - #${mappingJobDetails.id}`;
  if (isClinicJob(mappingJobDetails)) {
    title += ` (Clinic ${mappingJobDetails.practice_id} - ${mappingJobDetails.practice_name} - ${mappingJobDetails.practice_city}, ${mappingJobDetails.practice_state})`;
  } else if (
    isCustomJob(mappingJobDetails) &&
    mappingJobDetails.project_tracking
  ) {
    title += ' (' + mappingJobDetails.project_tracking + ')';
  }
  return title;
}

export function jobBelongsToUser(jobDetails, userId) {
  return jobDetails.claimed_by && jobDetails.claimed_by === userId;
}

export function jobActiveBelongsToUser(jobDetails, userId) {
  return (
    jobBelongsToUser(jobDetails, userId) &&
    mappableJobStatuses.includes(jobDetails.status)
  );
}

export function jobMappingsAllowedForUser(jobDetails, userInfo) {
  return (
    (jobBelongsToUser(jobDetails, userInfo.id) &&
      mappableJobStatuses.includes(jobDetails.status)) ||
    (isAdmin(userInfo) && viewableJobStatuses.includes(jobDetails.status))
  );
}

export function jobMappingsEditableByUser(jobDetails, userInfo) {
  return (
    mappableJobStatuses.includes(jobDetails.status) &&
    jobDetails.claimed_by &&
    (isAdmin(userInfo) || jobDetails.claimed_by === userInfo.id)
  );
}

export function isClinicJob(mappingJobDetails) {
  return mappingJobDetails.job_type === JOB_TYPES.clinic.value;
}

export function isCustomJob(mappingJobDetails) {
  return mappingJobDetails.job_type === JOB_TYPES.custom.value;
}

export function clinicDetailsAllowedForUser(mappingJobDetails, userInfo) {
  return (
    isClinicJob(mappingJobDetails) &&
    (isAdmin(userInfo) || jobBelongsToUser(mappingJobDetails, userInfo.id))
  );
}

export function canMarkJobMappingComplete(jobDetails, userInfo) {
  return (
    jobDetails.claimed_by &&
    jobDetails.status === JOB_STATUS_IN_PROGRESS &&
    (jobDetails.claimed_by === userInfo.id || isAdmin(userInfo))
  );
}

export function canMarkJobCompleted(jobDetails, userInfo) {
  return isAdmin(userInfo) && jobDetails.status === JOB_STATUS_MAPPING_COMPLETE;
}

export function canMarkJobAsInvoiced(jobDetails, userInfo) {
  return (
    isSuperAdmin(userInfo) &&
    !jobDetails.invoiced &&
    INVOICABLE_JOB_STATUSES.includes(jobDetails.status)
  );
}

export const downloadJobsCSV = (gridApi) => {
  gridApi.exportDataAsCsv({
    columnKeys: JOBS_CSV_EXPORT_COLUMNS,
    fileName: `jobs-data-${moment(Date.now()).format(
      'YYYY-MM-DD_hh.mm.ss'
    )}.csv`,
    processCellCallback: (params) => {
      if (params.column.colId === 'id') {
        return `#${params.value}`;
      }
      if (params.column.colId === 'job_type') {
        return jobTypes[params.value];
      }
      return params.value;
    },
  });
};

export function canUnhideJobFromMapper(jobDetails, userInfo) {
  return (
    isAdmin(userInfo) &&
    jobDetails?.status === JOB_STATUS_IN_PROGRESS &&
    jobDetails?.hidden_from_mapper
  );
}
