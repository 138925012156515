import { Link } from 'react-router-dom';

export default function LinkButton({
  text,
  fontWeight,
  fontSize,
  textColor,
  hoverTextColor,
  navigation,
}) {
  const className = `cursor-pointer hover:underline 
  ${textColor ? textColor : 'text-vs-green'}
  ${hoverTextColor ? hoverTextColor : 'hover:text-vs-green-hover'}
  ${fontWeight ? fontWeight : ''} 
  ${fontSize ? fontSize : ''}`;

  return (
    <Link className={className} to={navigation}>
      {text}
    </Link>
  );
}
