const NumberInputWithoutScroll = ({
  id,
  label,
  className,
  defaultValue,
  registerProps,
  step,
  labelClassName = 'formLabel',
}) => {
  return (
    <>
      <input
        id={id}
        type="number"
        onWheel={(e) => e.target.blur()}
        onKeyDown={(e) => {
          if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
          }
        }}
        className={`no-scroll-number-input ${className || ''}`}
        {...registerProps}
        {...(defaultValue && { defaultValue })}
        {...(step && { step })}
      />
      <label htmlFor={id} className={labelClassName}>
        {label}
      </label>
    </>
  );
};

export default NumberInputWithoutScroll;
