import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ColoredButton from '../../common/ColoredButton';
import Select, { createFilter } from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import OutlineButton from '../../common/OutlineButton';
import { digitRgx, MAX_TEXT_LENGTH } from '../../../constants/constants';
import {
  priorityFormSelect,
  RATE_TYPES_SELECT,
} from '../../../constants/jobConstants';
import DatePicker from 'react-datepicker';
import moment from 'moment/moment';
import {
  createClinicJob,
  fetchJobCreationEligibility,
} from '../../../services/jobServices';
import { parseError } from '../../../helpers/errorHelper';
import { formSelectStyle } from '../../../helpers/selectHelper';
import AsyncSelect from 'react-select/async';
import { WindowedMenuList } from 'react-windowed-select';
import { enterPressed } from '../../../utils/helper';
import { isMapper } from '../../../helpers/userHelper';
import NumberInputWithoutScroll from '../../common/NumberInputWithoutScroll';
import { VSO_TEAM_ROLE } from '../../../constants/roles';
import CodeLimitModal from './CodeLimitModal.jsx';
import totalCodesButton from '../../../assets/icons/total_codes_button.svg';
import totalCodesButtonActive from '../../../assets/icons/total_codes_button_active.svg';

export default function ClinicJobForm({
  onCancel,
  availablePractices,
  onSave,
}) {
  const [serverError, setServerError] = useState(null);
  const [practiceAutomapped, setPracticeAutomapped] = useState(true);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [numberOfCodes, setNumberOfCodes] = useState('-');
  const [numberOfTotalCodes, setNumberOfTotalCodes] = useState('-');
  const [showNumberOfTotalCodes, setShowNumberOfTotalCodes] = useState(false);
  const [showNumberOfCodesTooltip, setShowNumberOfCodesTooltip] =
    useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [modalData, setModalData] = useState(null);
  const { activeUsersOnly } = useSelector((state) => state.jobsTable);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const assignedUser = watch('assign_to', false);
  const practice = watch('practice');

  let assignToUsers = activeUsersOnly?.filter((u) => u.role !== VSO_TEAM_ROLE);

  useEffect(() => {
    if (!assignedUser || !isMapper(assignedUser)) {
      setValue('hidden_from_mapper', false);
    }
  }, [assignedUser]);

  const submitForm = (data) => {
    //prepare data
    setButtonsDisabled(true);
    data.available_at = moment(data.available_at).format('YYYY-MM-DD');
    data.practice_id = data.practice.id;
    data.priority = data.priority.value;
    data.rate_type = data.rate_type.value;
    if (data.assign_to) data.claimed_by = data.assign_to.id;
    data.exclude_codes_without_transactions = false;
    delete data.practice;
    delete data.assign_to;
    createJob(data);
  };

  const createJob = useCallback((data) => {
    createClinicJob(data)
      .then(async () => {
        await onSave();
        setButtonsDisabled(false);
        onCancel();
      })
      .catch((err) => {
        setButtonsDisabled(false);
        if (err.response?.data?.error_code === 'invalid_job_code_count') {
          data.exclude_codes_without_transactions = true;
          setModalData(data);
        } else {
          setServerError(parseError(err).description);
        }
      });
  }, []);

  const handleExcludeCodes = useCallback(() => {
    createJob(modalData);
    setModalData(null);
  }, [modalData]);

  const fetchCodeCount = (data) => {
    fetchJobCreationEligibility(data.id, {
      include_codes_with_historical_revenue: false,
    })
      .then((response) => {
        setNumberOfCodes(response.code_count);
        setSaveButtonDisabled(!response.automapped);
        setPracticeAutomapped(response.automapped);
      })
      .catch(() => {
        setNumberOfCodes('unavailable');
      });
  };

  const fetchTotalCodeCount = () => {
    if (
      practice?.id &&
      (numberOfTotalCodes === '-' || numberOfTotalCodes === 'unavailable')
    ) {
      fetchJobCreationEligibility(practice.id, {
        include_codes_with_historical_revenue: true,
      })
        .then((response) => {
          setNumberOfTotalCodes(response.code_count);
        })
        .catch(() => {
          setNumberOfTotalCodes('unavailable');
        });
    }
  };

  const filterOptions = (inputValue) => {
    const isValid =
      inputValue && (inputValue.match(digitRgx) || inputValue.length >= 3);
    return isValid
      ? availablePractices.filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        )
      : [];
  };

  const loadOptions = (inputValue, callback) => {
    callback(filterOptions(inputValue));
  };

  const validateCodes = () => {
    if (numberOfCodes < 1) {
      return 'This clinic has no codes, please select another clinic.';
    } else if (numberOfCodes > 20000) {
      return 'This clinic has more than 20.000 codes, please create multiple custom jobs for it.';
    }
    return true;
  };

  const handleMouseEnter = () => {
    if (!showNumberOfTotalCodes) setShowNumberOfCodesTooltip(true);
  };

  const handleMouseDown = () => {
    setShowNumberOfCodesTooltip(false);
    setShowNumberOfTotalCodes(true);
    fetchTotalCodeCount();
  };

  const handleMouseUp = () => {
    setShowNumberOfCodesTooltip(true);
    setShowNumberOfTotalCodes(false);
  };

  const handleMouseLeave = () => {
    setShowNumberOfCodesTooltip(false);
    setShowNumberOfTotalCodes(false);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(submitForm)}
        onKeyDown={(e) => {
          enterPressed(e) && e.preventDefault();
        }}
      >
        <div className="relative mb-9">
          <Controller
            id="practice"
            name="practice"
            control={control}
            rules={{
              required: 'Practice is required',
              validate: {
                codes: () => validateCodes(),
              },
            }}
            render={({ field }) => (
              <AsyncSelect
                defaultOptions
                cacheOptions
                loadOptions={loadOptions}
                components={{ MenuList: WindowedMenuList }}
                noOptionsMessage={() => null}
                filterOption={createFilter({ ignoreAccents: false })}
                id="practice"
                inputId="practice-input"
                {...field}
                className="formInput select"
                isDisabled={buttonsDisabled}
                isClearable={true}
                onChange={(value) => {
                  if (value) {
                    fetchCodeCount(value);
                  } else {
                    setNumberOfCodes('-');
                    setPracticeAutomapped(true);
                  }
                  setNumberOfTotalCodes('-');
                  field.onChange(value);
                }}
                styles={formSelectStyle(errors, 'practice')}
                options={availablePractices}
              />
            )}
          />
          <label htmlFor="practice-input" className="formLabel required">
            Practice
          </label>
          {errors.practice?.type === 'required' ? (
            <p className="formError">{errors.practice.message}</p>
          ) : (
            <p
              className="absolute text-gray-400 mt-1 text-xs min-h-[20px] w-full"
              data-testid="practice-eligibility-info"
            >
              {!practiceAutomapped && (
                <span className="text-red-500">Practice not automapped</span>
              )}
              <span className="float-right pl-1">
                {showNumberOfTotalCodes ? (
                  <span className="text-dark-gray">{numberOfTotalCodes} </span>
                ) : (
                  <span className="text-dark-gray">{numberOfCodes} </span>
                )}
                <span>codes estimated</span>
                <span
                  onMouseEnter={handleMouseEnter}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseLeave}
                >
                  <img
                    className="inline ml-2 cursor-pointer"
                    src={
                      showNumberOfTotalCodes
                        ? totalCodesButtonActive
                        : totalCodesButton
                    }
                    alt="total-codes-button"
                  />
                  {showNumberOfCodesTooltip && (
                    <span className="code-count-tooltip">
                      Switch to Total Code Count
                      <span className="code-count-tooltip-arrow" />
                    </span>
                  )}
                </span>
              </span>
            </p>
          )}
        </div>
        <div className="relative mb-8">
          <NumberInputWithoutScroll
            id="salesforce_case_number"
            label="Salesforce Case Number"
            registerProps={register('salesforce_data.salesforce_case_number')}
            className="formInput"
          />
        </div>
        <div className="relative mb-8">
          <NumberInputWithoutScroll
            id="onboarding_category"
            label="Onboarding Category"
            registerProps={register('salesforce_data.onboarding_category')}
            className="formInput"
          />
        </div>
        <div data-testid="assign_to_container" className="relative mb-9">
          <Controller
            id="assign_to"
            name="assign_to"
            control={control}
            render={({ field }) => (
              <Select
                components={{ MenuList: WindowedMenuList }}
                filterOption={createFilter({ ignoreAccents: false })}
                id="assign_to"
                inputId="assign_to-input"
                {...field}
                className="formInput select"
                isDisabled={buttonsDisabled}
                isClearable={true}
                options={assignToUsers}
              />
            )}
          />
          <label htmlFor="assign_to-input" className="formLabel">
            Assign to
          </label>
          <p className="absolute text-gray-400 mt-1 text-xs min-h-[20px]">
            Once assigned user can not be changed.
          </p>
        </div>
        {assignedUser && isMapper(assignedUser) && (
          <div className="relative mb-8">
            <input
              id="hidden_from_mapper"
              type="checkbox"
              className="border border-gray rounded-sm bg-white focus:ring-transparent"
              {...register('hidden_from_mapper')}
            />
            <label
              htmlFor="hidden_from_mapper"
              className="ml-1 text-xs text-gray-400"
            >
              Hide from mapper when posted
            </label>
          </div>
        )}
        <div className="relative mb-8">
          <Controller
            name="priority"
            control={control}
            rules={{ required: 'Priority is required' }}
            render={({ field }) => (
              <Select
                {...field}
                inputId="priority-input"
                className="formInput select"
                isDisabled={buttonsDisabled}
                styles={formSelectStyle(errors, 'priority')}
                options={priorityFormSelect}
              />
            )}
          />
          <label htmlFor="priority-input" className="formLabel required">
            Priority
          </label>
          {errors.priority?.type === 'required' && (
            <p className="formError">{errors.priority.message}</p>
          )}
        </div>
        <div className="relative mb-8">
          <input
            id="project_tracking"
            type="text"
            className="formInput"
            {...register('project_tracking')}
          />
          <label htmlFor="project_tracking" className="formLabel">
            Project Tracking
          </label>
        </div>
        <div className="relative mb-8">
          <NumberInputWithoutScroll
            id="rate"
            label="Rate ($)"
            registerProps={register('rate', { required: 'Rate is required' })}
            className={`formInput required${
              errors.rate ? ' validationError' : ''
            }`}
            labelClassName="formLabel required"
            step="0.01"
          />
          {errors.rate?.type === 'required' && (
            <p className="formError">{errors.rate.message}</p>
          )}
        </div>
        <div className="relative mb-8">
          <Controller
            name="rate_type"
            defaultValue={RATE_TYPES_SELECT[0]}
            control={control}
            rules={{ required: 'Rate Type is required' }}
            render={({ field }) => (
              <Select
                {...field}
                inputId="rate-type-input"
                className="formInput select"
                isDisabled={buttonsDisabled}
                styles={formSelectStyle(errors, 'rate-type')}
                options={RATE_TYPES_SELECT}
              />
            )}
          />
          <label htmlFor="rate-type-input" className="formLabel required">
            Rate Type
          </label>
          {errors.rate_type?.type === 'required' && (
            <p className="formError">{errors.rate_type.message}</p>
          )}
        </div>
        <div className="relative mb-8">
          <Controller
            name="available_at"
            control={control}
            render={({ field }) => (
              <DatePicker
                id="available_at"
                className="formInput"
                wrapperClassName="formDatepicker"
                dateFormat="yyyy-MM-dd"
                onChange={(date) => field.onChange(date)}
                selected={assignedUser ? new Date() : field.value}
                readOnly={assignedUser}
                autoComplete="off"
              />
            )}
          />
          <label htmlFor="available_at" className="formLabel">
            Date Available
          </label>
        </div>
        <div className="relative">
          <textarea
            id="admin_description"
            type="text"
            rows="3"
            className="textarea form"
            {...register('admin_description', {
              maxLength: MAX_TEXT_LENGTH,
            })}
          />
          <label htmlFor="admin_description" className="formAreaLabel">
            Description
          </label>
          {errors.admin_description?.type === 'maxLength' && (
            <p className="absolute text-red-500 w-80 mt-1 text-xs">
              Admin description can have up to {MAX_TEXT_LENGTH} characters
            </p>
          )}
        </div>
        <div className="relative">
          <textarea
            id="admin_notes"
            type="text"
            rows="3"
            className="textarea form"
            {...register('admin_notes', {
              maxLength: MAX_TEXT_LENGTH,
            })}
          />
          <label htmlFor="admin_notes" className="formAreaLabel">
            Admin Notes
          </label>
          {errors.admin_notes?.type === 'maxLength' && (
            <p className="absolute text-red-500 w-80 mt-1 text-xs">
              Admin notes can have up to {MAX_TEXT_LENGTH} characters
            </p>
          )}
        </div>
        <div className="relative mb-12">
          <div className="formSectionTitle">Protocol:</div>
          <div className="formSection">
            <div className="relative w-[30%]">
              <label htmlFor="protocolDhpp" className="formLabel">
                DHPP
              </label>
              <input
                id="protocolDhpp"
                type="text"
                className="formInput inline"
                {...register('vaccine_protocol.dhpp')}
              />
            </div>
            <div className="relative w-[30%]">
              <label htmlFor="protocolFvrcp" className="formLabel">
                FVRCP
              </label>
              <input
                id="protocolFvrcp"
                type="text"
                className="formInput inline"
                {...register('vaccine_protocol.fvrcp')}
              />
            </div>
            <div className="relative w-[30%]">
              <label htmlFor="protocolRabies" className="formLabel">
                Rabies
              </label>
              <input
                id="protocolRabies"
                type="text"
                className="formInput inline"
                {...register('vaccine_protocol.rabies')}
              />
            </div>
          </div>
          <div className="formServerError">{serverError}</div>
          {errors.practice?.type === 'codes' && (
            <p className="formError pt-2">{errors.practice?.message}</p>
          )}
        </div>
        <div className="formButtonsPanel">
          <OutlineButton
            outlineColor="border-gray"
            textColor="text-gray"
            text="Cancel"
            widthClass="w-[120px]"
            heightClass="h-10"
            onClick={onCancel}
            disabled={buttonsDisabled}
          />
          <ColoredButton
            fillColor="bg-vs-green"
            text="Save"
            widthClass="w-[120px]"
            heightClass="h-10"
            submit={true}
            disabled={saveButtonDisabled || buttonsDisabled}
          />
        </div>
      </form>
      {modalData && (
        <CodeLimitModal
          onButtonClick={handleExcludeCodes}
          onClose={() => setModalData(null)}
          isOpen={!!modalData}
        />
      )}
    </>
  );
}
