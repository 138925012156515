import { useForm } from 'react-hook-form';
import OutlineButton from '../../common/OutlineButton';
import ColoredButton from '../../common/ColoredButton';
import { useSelector } from 'react-redux';

export default function UpdateDosesForm({
  onCancel,
  dosesType,
  onSubmitHandler,
}) {
  const { buttonsDisabled, serverError } = useSelector((state) => state.modal);

  const onSubmit = (data) => {
    let params = {
      batch_action: `batch ${dosesType} update`,
    };
    params[dosesType] = data.doses;
    onSubmitHandler(params);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={'text-13 my-6 px-1'}>
        <input
          type="number"
          {...register('doses', { required: true })}
          className="m-2 border border-gray rounded-sm"
          disabled={buttonsDisabled}
        />
        <p className="text-red-500 text-center h-8 w-60 text-xs">
          {serverError}
        </p>
        {errors.doses?.type === 'required' && (
          <p className="absolute text-red-500 text-center w-60 text-xs">
            Doses are required
          </p>
        )}
      </div>
      <OutlineButton
        text="Cancel"
        heightClass="h-10"
        onClick={onCancel}
        disabled={buttonsDisabled}
      />
      <ColoredButton
        text="OK"
        heightClass="h-10"
        submit={true}
        disabled={buttonsDisabled}
      />
    </form>
  );
}
