import { Dialog } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import { modalClosed } from '../../../features/modal/modalSlice';

export default function JobDetailsEditModal({ openState, title, form }) {
  const dispatch = useDispatch();
  return (
    <Dialog open={openState} onClose={() => dispatch(modalClosed())}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex items-center justify-center p-4 text-center">
          <Dialog.Panel className="w-[500px] rounded-sm bg-white px-14 py-8 text-left align-middle shadow-xl flex-col">
            <Dialog.Title
              as="h3"
              className="text-center text-lg font-medium leading-6 font-bold text mb-8"
            >
              {title}
            </Dialog.Title>
            {form}
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
