import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getUserConfigurations,
  updateUserConfiguration,
  deleteUserConfiguration,
  createUserConfiguration,
} from '../../services/userConfigurationServices';
import { parseError } from '../../helpers/errorHelper';

export const loadUserTableConfigurations = createAsyncThunk(
  'userConfig/getUserConfigurations',
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await getUserConfigurations();
      return data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const addUserTableConfiguration = createAsyncThunk(
  'userConfig/addUserTableConfiguration',
  async (data, { rejectWithValue }) => {
    try {
      const res = await createUserConfiguration(data);
      return res.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const editUserTableConfiguration = createAsyncThunk(
  'userConfig/editUserTableConfiguration',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await updateUserConfiguration(id, data);
      return res.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const removeUserTableConfiguration = createAsyncThunk(
  'userConfig/deleteUserTableConfiguration',
  async (id, { rejectWithValue }) => {
    try {
      await deleteUserConfiguration(id);
      return id;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);
