import { createSlice } from '@reduxjs/toolkit';
import {
  loadUserTableConfigurations,
  addUserTableConfiguration,
  editUserTableConfiguration,
  removeUserTableConfiguration,
} from './userConfigActions';
import { logout, resetState } from '../user/userActions';
import { mapUserTableConfiguration } from '../../helpers/userConfigHelper.js';

const initialState = {
  userTableConfigurations: null,
  activeUserTableConfigurations: {},
  loadingUserTableConfigurationsError: null,
};

export const userConfigSlice = createSlice({
  name: 'userConfig',
  initialState: initialState,
  extraReducers: {
    [loadUserTableConfigurations.pending]: (state) => {
      state.loadingUserTableConfigurationsError = null;
    },
    [loadUserTableConfigurations.fulfilled]: (state, { payload }) => {
      state.userTableConfigurations = payload.map(mapUserTableConfiguration);

      state.activeUserTableConfigurations = payload.reduce((result, config) => {
        if (config.active) {
          result[config.config_type] = mapUserTableConfiguration(config);
        }
        return result;
      }, {});

      state.loadingUserTableConfigurationsError = null;
    },
    [loadUserTableConfigurations.rejected]: (state, { payload }) => {
      state.userTableConfigurations = null;
      state.activeUserTableConfigurations = {};
      state.loadingUserTableConfigurationsError = payload;
    },
    [addUserTableConfiguration.fulfilled]: (state, { payload }) => {
      const newConfig = mapUserTableConfiguration(payload);

      state.userTableConfigurations.push(newConfig);
      state.activeUserTableConfigurations[newConfig.configType] = newConfig;
    },
    [editUserTableConfiguration.fulfilled]: (state, { payload }) => {
      const updatedConfig = mapUserTableConfiguration(payload);

      const index = state.userTableConfigurations.findIndex(
        (config) => config.id === payload.id
      );
      state.userTableConfigurations[index] = updatedConfig;

      if (payload.active) {
        state.activeUserTableConfigurations[updatedConfig.configType] =
          updatedConfig;
      } else {
        delete state.activeUserTableConfigurations[updatedConfig.configType];
      }
    },
    [removeUserTableConfiguration.fulfilled]: (state, { payload }) => {
      state.userTableConfigurations = state.userTableConfigurations.filter(
        (config) => {
          const keepConfig = config.id !== payload;

          if (
            !keepConfig &&
            state.activeUserTableConfigurations[config.configType]?.id ===
              payload
          ) {
            delete state.activeUserTableConfigurations[config.configType];
          }

          return keepConfig;
        }
      );
    },
    [logout]: () => initialState,
    [resetState]: () => initialState,
  },
});

export default userConfigSlice.reducer;
