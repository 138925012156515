import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  batchUpdateJobCodeTagsAdd,
  batchUpdateJobCodeTagsRemove,
  batchUpdateJobCodeTagsReplace,
  getJobCodeMappings,
  getUnverifiedCodes,
  updateJobCodeMappings,
  updateJobTableRow,
} from '../../services/mappingDataServices';
import { createPortal } from 'react-dom';
import { AgGridReact } from 'ag-grid-react';
import {
  markJobCompletedClicked,
  markJobCompleteMappingClicked,
  modalClosed,
  serverErrorInModal,
  invoiceJobClicked,
  unhideJobClicked,
  confirmButtonClicked,
} from '../../features/modal/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  code_tags_ids,
  DEFAULT_PAGE_SIZE,
  free_doses,
  has_transactions,
  paid_doses,
  pms_code_vetsuccess_id,
  revenue_category_id,
  review_status,
  times_used,
  UNDO_REDO_ACTION,
  UNDO_REDO_EDIT_LIMIT,
  USER_CONFIG_TYPE_JOB_MAPPINGS,
  VACCINE_COLUMNS,
  verification_pipeline_status_id,
  verified,
} from '../../constants/constants';
import {
  UPDATE_WAITING_TIME_LIMIT,
  RATE_TYPE_PER_HOUR,
  mappableJobStatuses,
  CLINIC_JOB_TYPE,
} from '../../constants/jobConstants';
import { cancelNavigationPrompt } from '../../cancelNavigationPrompt';
import ConfirmationModal from '../common/ConfirmationModal';
import { parseError } from '../../helpers/errorHelper';
import {
  collapseInactiveFilters,
  getActiveFiltersStatus,
  resetJobMappingsFilters,
  getMappingTableFilters,
  mapFilterLayout,
} from '../../helpers/tableFiltersHelper';
import {
  defaultColumnDefinition,
  getColumnDefinitions,
} from './mappingJobColumnDefinitions';
import {
  advanceToNextRow,
  clearLongRequest,
  fillOperation,
  filterOutUnchangedCodes,
  formatRequest,
  getContextMenuItems,
  getElementById,
  isDosesColumn,
  isPseudoeditableColumn,
  onCellEditingStopped,
  onCellKeyDown,
  onCellMouseOver,
  onCellValueChanged,
  onRangeSelectionChanged,
  paginationNumberFormatter,
  sidebarDef,
} from '../../helpers/tableHelper';
import PageSize from './PageSize';
import { renderFilterActions } from './tableRenders';
import BatchActionsModals from './BatchActionsModals';
import ActiveFilters from './filters/ActiveFilters';
import MappingDropdown from '../mapping/MappingDropdown';
import ProtocolInfo from '../job/ProtocolInfo';
import JobCodeCountInfo from './JobCodeCountInfo';
import { updateTableData } from '../../services/tableServices';
import {
  calculateMappedCodeCount,
  downloadJobsCSVFile,
  formatHours,
  getJobItems,
  hoursEditableForUser,
} from '../../helpers/jobsTableHelper';
import JobMappingsStatusIcons from './JobMappingsStatusIcons';
import {
  isClinicJob,
  isCustomJob,
  jobMappingsEditableByUser,
} from '../../helpers/jobsHelper';
import OutlineIconButton from '../common/OutlineIconButton';
import clearSortIcon from '../../assets/icons/clear-sort.svg';
import {
  getMappableElements,
  getUserSelectElementsForMapper,
} from '../../helpers/selectHelper';
import { isAdmin } from '../../helpers/userHelper';
import MinutesSpent from './MinutesSpent';
import { openViewJobDetails } from '../../features/modal/modalActions';
import UserIdIcon from './UserIdIcon';
import UserTableConfiguration from '../mapping/UserTableConfiguration';
import UndoActions from './UndoActions';
import { getUndoRedoShortcut } from '../../helpers/commonHelper';
import {
  COMPLETE_MAPPING_JOB_NOT_ALLOWED_MODAL,
  COMPLETE_MAPPING_JOB_PRACTICE_TYPE_WARNING_MODAL,
  COMPLETE_MAPPING_JOB_MODAL,
  COMPLETE_JOB_NOT_ALLOWED_MODAL,
  COMPLETE_JOB_PRACTICE_TYPE_WARNING_MODAL,
} from '../../constants/modals';
import { ErrorContext } from '../../ErrorContext';
import {
  addDragEvents,
  didUserConfigChange,
  removeDragEvents,
  resetDragEvents,
} from '../../helpers/userConfigurationHelper.js';

function shouldHideCustomCodeTags(userInfo, jobDetails) {
  return (
    isClinicJob(jobDetails) ||
    (isCustomJob(jobDetails) && !jobDetails.include_excluded_codes)
  );
}

export default function JobMappingTable({ jobDetails, gridOptions }) {
  const { setErrorAlert } = useContext(ErrorContext);
  const {
    allCodeTags,
    nonCustomCodeTags,
    allRevenueCategories,
    practices,
    groups,
    practiceGroupRelationships,
    parasiticideRevenueCategories,
    drugRevenueCategories,
    dietRevenueCategories,
    labRevenueCategories,
  } = useSelector((state) => state.tableData);
  const { pipelineStatusesForSelect } = useSelector(
    (state) => state.pipelineStatus
  );
  const jobId = jobDetails.id;
  const jobType = jobDetails.job_type;
  const { userInfo } = useSelector((state) => state.user);
  const { userTableConfigurations, activeUserTableConfigurations } =
    useSelector((state) => state.userConfig);
  const activeUserTableConfiguration = useMemo(
    () => activeUserTableConfigurations?.[USER_CONFIG_TYPE_JOB_MAPPINGS],
    [activeUserTableConfigurations]
  );
  const dispatch = useDispatch();
  const [isTableReady, setIsTableReady] = useState(false);
  const gridRef = useRef();
  const tableRef = useRef(null);
  const [rowData, setRowData] = useState();
  const [isClearSortVisible, setClearSortVisible] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const [isUndoAvailable, setUndoAvailable] = useState(false);
  const [isRedoAvailable, setRedoAvailable] = useState(false);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(true);
  const [activeFiltersStatus, setActiveFiltersStatus] = useState([]);
  const [mappedCodeCount, setMappedCodeCount] = useState(null);
  const [showLeavePageConfirmationDialog, setShowLeavePageConfirmationDialog] =
    useState(false);
  const [populatedVaccineColumns, setPopulatedVaccineColumns] =
    useState(VACCINE_COLUMNS);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    cancelNavigationPrompt(showLeavePageConfirmationDialog);
  const selectionInProgressRef = useRef(false);
  const previousMouseOverColumnRef = useRef(null);
  const cellFocusedByKeyboardNavigationRef = useRef(false);
  const undoActionShortcut = getUndoRedoShortcut(UNDO_REDO_ACTION.undo);
  const redoActionShortcut = getUndoRedoShortcut(UNDO_REDO_ACTION.redo);
  const [isUserConfigChanged, setIsUserConfigChanged] = useState(false);
  const [currentPageSize, setCurrentPageSize] = useState(
    activeUserTableConfiguration?.configuration.pageSize || DEFAULT_PAGE_SIZE
  );

  const defaultFilterModel = useMemo(() => {
    return isCustomJob(jobDetails)
      ? {
          [verified.field]: { value: 'No' },
        }
      : {
          [verified.field]: { value: 'No' },
          [has_transactions.field]: { value: 'Yes' },
        };
  }, [jobDetails]);

  const availableCodeTags = useMemo(() => {
    return shouldHideCustomCodeTags(userInfo, jobDetails)
      ? nonCustomCodeTags
      : allCodeTags;
  }, []);

  const defaultFilterLayout = useMemo(
    () => getMappingTableFilters(userInfo.role, jobType),
    []
  );
  const filterLayout = useRef(
    activeUserTableConfiguration
      ? activeUserTableConfiguration.configuration.filterOrder
      : defaultFilterLayout
  );

  const alertUser = useCallback((e) => {
    e.preventDefault();
    e.returnValue = '';
  }, []);

  const fillHandleDataRef = useRef({
    requests: [],
    rows: [],
  });

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser, { once: true });

    return removeDragEvents;
  }, []);

  // Using gridOptions for testing purposes.
  // Note: Showing and hiding of columns only works if done directly via the grid api.
  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      gridOptions.api = gridRef.current?.api;
    }
  }, [gridRef.current?.api]);

  useEffect(() => {
    //we have to manually call setColumnDefs once populatedVaccineColumns is calculated, in order to remove empty ones.
    //since populatedVaccineColumns is Set, initialised with VACCINE_COLUMNS, we just have to watch the size change to know if we should recalculate definitions.
    if (
      isTableReady &&
      gridRef.current.api &&
      populatedVaccineColumns.size !== VACCINE_COLUMNS.size
    ) {
      gridRef.current.api.setGridOption(
        'columnDefs',
        getColumnDefinitions({
          tableRef,
          allRevenueCategories,
          availableCodeTags,
          practices,
          groups,
          practiceGroupRelationships,
          parasiticideRevenueCategories,
          drugRevenueCategories,
          dietRevenueCategories,
          labRevenueCategories,
          users,
          jobType,
          pipelineStatusesForSelect,
          populatedVaccineColumns,
          readOnly,
        })
      );
      // After setting column definitions, we also have to set column state and filter layout again
      if (activeUserTableConfiguration) {
        gridRef.current.api.applyColumnState({
          state: activeUserTableConfiguration.configuration.columnState,
          applyOrder: true,
        });
      }
      const filtersToolPanel =
        gridRef.current.api.getToolPanelInstance('filters');
      filtersToolPanel.setFilterLayout(mapFilterLayout(filterLayout.current));
      filtersToolPanel.expandFilters(Object.keys(defaultFilterModel));
    }
  }, [isTableReady, gridRef?.current?.api, populatedVaccineColumns]);

  const users = isAdmin(userInfo)
    ? useSelector((state) => state.tableData.users)
    : getUserSelectElementsForMapper(userInfo.id);
  const usersDetails = isAdmin(userInfo)
    ? useSelector((state) => state.tableData.usersDetails)
    : [];

  const readOnly = useMemo(
    () => !jobMappingsEditableByUser(jobDetails, userInfo),
    []
  );

  const columnDefs = useMemo(
    () =>
      getColumnDefinitions({
        tableRef,
        allRevenueCategories,
        availableCodeTags,
        practices,
        groups,
        practiceGroupRelationships,
        parasiticideRevenueCategories,
        drugRevenueCategories,
        dietRevenueCategories,
        labRevenueCategories,
        pipelineStatusesForSelect,
        users,
        jobType,
        populatedVaccineColumns,
        readOnly,
      }),
    []
  );

  const defaultColDef = useMemo(() => defaultColumnDefinition(readOnly), []);

  const onGridReady = useCallback((e) => {
    e.api.resetColumnState();

    const filtersToolPanel = e.api.getToolPanelInstance('filters');
    filtersToolPanel.setFilterLayout(mapFilterLayout(filterLayout.current));
    getJobCodeMappings(jobId)
      .then((result) => {
        let populatedVaccines = [];
        result.forEach((row) => {
          let currentPopulated = Object.keys(row.vacc_para_data).filter((key) =>
            VACCINE_COLUMNS.has(key)
          );
          populatedVaccines = populatedVaccines.concat(currentPopulated);
        });
        setPopulatedVaccineColumns(new Set(populatedVaccines));
        setRowData(result);
        filtersToolPanel.expandFilters(Object.keys(defaultFilterModel));
        setIsTableReady(true);
      })
      .catch((err) => {
        setErrorAlert({ error: err });
        e.api.hideOverlay();
      });
  }, []);

  useEffect(() => {
    if (rowData && gridRef.current.api) {
      changeMappedCodeCount();
    }
  }, [rowData, gridRef]);

  useEffect(() => {
    gridRef.current.api?.setGridOption('paginationPageSize', currentPageSize);
    gridRef.current.api?.deselectAll();
  }, [currentPageSize]);

  const setPageSize = (value) => {
    setCurrentPageSize(value);
    setIsUserConfigChanged(true);
  };

  const changeMappedCodeCount = useCallback(() => {
    if (gridRef?.current?.api) {
      const codeCount = calculateMappedCodeCount(
        gridRef.current.api,
        jobDetails
      );
      setMappedCodeCount(codeCount);
    }
  }, [gridRef?.current?.api]);

  const onPaginationChanged = useCallback((event) => {
    if (event.newPage) {
      event.api.deselectAll();
    }
  }, []);

  const onGridSizeChanged = useCallback((e) => {
    e.api.refreshHeader();
  }, []);

  const getRowId = useCallback((params) => {
    return params.data[pms_code_vetsuccess_id.field];
  }, []);

  const onFilterChanged = useCallback((e) => {
    collapseInactiveFilters(e.api, filterLayout.current);
    e.api.deselectAll();
    setActiveFiltersStatus(getActiveFiltersStatus(e.api.getFilterModel()));
    if (e.api.paginationGetRowCount() === 0) {
      e.api.showNoRowsOverlay();
    } else {
      e.api.hideOverlay();
    }
  }, []);

  const onFirstDataRendered = useCallback((e) => {
    e.api.onFilterChanged();
    addDragEvents(tableRef, filterLayout, setIsUserConfigChanged);
  }, []);

  const updateUndoState = useCallback((params) => {
    setUndoAvailable(params.api.getCurrentUndoSize() > 0);
    setRedoAvailable(params.api.getCurrentRedoSize() > 0);
  }, []);

  const onCellValueChangedCallback = useCallback((params) => {
    onCellValueChanged(params, updateCallback, setErrorAlert);
    updateUndoState(params);
  }, []);

  const getContextMenuItemsCallback = useCallback((event) => {
    return !readOnly
      ? getContextMenuItems(
          event,
          dispatch,
          getBatchUpdateHandler,
          updateJobCodeMappings,
          jobType
        )
      : null;
  }, []);

  const onCellContextMenu = useCallback((event) => {
    event.node.setSelected(true);
  }, []);

  const onRangeSelectionChangedCallback = useCallback((event) => {
    onRangeSelectionChanged(event, selectionInProgressRef);
  }, []);

  const onCellEditingStoppedCallback = useCallback((event) => {
    onCellEditingStopped(event, updateCallback, setErrorAlert);
  }, []);

  const onCellKeyDownCallback = useCallback((e) => {
    !readOnly &&
      onCellKeyDown(
        setIsRequest,
        e.event,
        selectionInProgressRef,
        e.api,
        setErrorAlert,
        availableCodeTags,
        dispatch,
        getBatchUpdateHandler,
        updateJobCodeMappings,
        cellFocusedByKeyboardNavigationRef,
        changeMappedCodeCount,
        jobId
      );
  }, []);

  const sideBar = useMemo(() => {
    return sidebarDef;
  }, []);

  const createProtocolInfoPortal = () => {
    if (
      isTableReady &&
      jobDetails &&
      document.querySelector('#protocol-placeholder')
    ) {
      return createPortal(
        <ProtocolInfo protocol={jobDetails.vaccine_protocol} />,
        document.querySelector('#protocol-placeholder')
      );
    }
  };

  const createProtocolInfo = useMemo(
    () => createProtocolInfoPortal(),
    [isTableReady, jobDetails]
  );

  const createUndoActionsPortal = () => {
    if (
      !readOnly &&
      isTableReady &&
      document.querySelector('#undo-section-placeholder')
    ) {
      return createPortal(
        <UndoActions
          undoAvailable={isUndoAvailable}
          redoAvailable={isRedoAvailable}
          undoAction={() => gridRef.current?.api.undoCellEditing()}
          redoAction={() => gridRef.current?.api.redoCellEditing()}
          undoActionShortcut={undoActionShortcut}
          redoActionShorcut={redoActionShortcut}
        />,
        document.querySelector('#undo-section-placeholder')
      );
    }
  };

  const createUndoActions = useMemo(
    () => createUndoActionsPortal(),
    [isTableReady, isUndoAvailable, isRedoAvailable, gridRef.current?.api]
  );

  const createFilterStatusPortal = () => {
    if (isTableReady) {
      return createPortal(
        <ActiveFilters status={activeFiltersStatus} />,
        document.querySelector('.ag-tool-panel-wrapper')
      );
    }
  };

  const createFilterStatus = useMemo(
    () => createFilterStatusPortal(),
    [isTableReady, activeFiltersStatus]
  );

  const downloadCSVFile = useCallback(() => {
    downloadJobsCSVFile(
      gridRef.current.api,
      allRevenueCategories,
      availableCodeTags,
      users
    );
  }, [gridRef.current?.api]);

  const markJobCompleteMapping = () => {
    // here we have to look at redux for mappingJobDetals,
    // because missing practice type modals offer possibility to edit job details, after which this function does not see the change
    if (
      jobDetails.job_type === CLINIC_JOB_TYPE &&
      jobDetails.practice_type.length === 0 &&
      jobDetails.unverified_code_count > 100
    ) {
      dispatch(
        markJobCompleteMappingClicked({
          visibleModal: COMPLETE_MAPPING_JOB_NOT_ALLOWED_MODAL,
        })
      );
    } else {
      let allCodesVerified = true;
      gridRef.current.api.forEachNode((node) => {
        if (
          node.data[verified.field] === false &&
          node.data[times_used.field] > 1
        ) {
          allCodesVerified = false;
        }
      });
      getUnverifiedCodes(jobDetails.id)
        .then((unverifiedCodesExists) => {
          jobDetails.job_type === CLINIC_JOB_TYPE &&
          jobDetails.practice_type.length === 0
            ? dispatch(
                markJobCompleteMappingClicked({
                  visibleModal:
                    COMPLETE_MAPPING_JOB_PRACTICE_TYPE_WARNING_MODAL,
                  payloadForNextModal: {
                    unverifiedCodesExists: unverifiedCodesExists,
                    allCodesVerified: allCodesVerified,
                  },
                })
              )
            : dispatch(
                markJobCompleteMappingClicked({
                  visibleModal: COMPLETE_MAPPING_JOB_MODAL,
                  unverifiedCodesExists: unverifiedCodesExists,
                  allCodesVerified: allCodesVerified,
                  ...(jobDetails.rate_type === RATE_TYPE_PER_HOUR && {
                    mappedTime: formatHours(jobDetails.mapped_time),
                  }),
                })
              );
        })
        .catch((error) => {
          setErrorAlert({ error: error });
        });
    }
  };

  const markJobCompleted = () => {
    if (
      jobDetails.job_type === CLINIC_JOB_TYPE &&
      jobDetails.practice_type.length === 0 &&
      jobDetails.unverified_code_count > 100
    ) {
      dispatch(
        markJobCompletedClicked({
          visibleModal: COMPLETE_JOB_NOT_ALLOWED_MODAL,
        })
      );
    } else if (
      jobDetails.job_type === CLINIC_JOB_TYPE &&
      jobDetails.practice_type.length === 0 &&
      jobDetails.unverified_code_count <= 100
    ) {
      dispatch(
        markJobCompletedClicked({
          visibleModal: COMPLETE_JOB_PRACTICE_TYPE_WARNING_MODAL,
        })
      );
    } else dispatch(markJobCompletedClicked());
  };

  const markJobInvoiced = () => {
    dispatch(invoiceJobClicked());
  };

  const unhideJob = () => {
    dispatch(unhideJobClicked());
  };

  const viewJobDetails = async () => {
    try {
      await dispatch(openViewJobDetails(jobDetails.id)).unwrap();
    } catch (error) {
      setErrorAlert({ parsedError: error });
    }
  };

  const onDragStarted = useCallback(() => {
    selectionInProgressRef.current = true;
  }, []);

  const onDragStopped = useCallback(() => {
    selectionInProgressRef.current = false;
  }, []);

  const onDialogCancel = useCallback(() => {
    dispatch(modalClosed());
  }, []);

  function getBatchUpdateHandler(updateFunction, updateColumns, modal = true) {
    return (formParams) => {
      if (modal) {
        dispatch(confirmButtonClicked());
      }
      let selectedPmsIds = gridRef.current.api
        .getSelectedNodes()
        .filter((node) =>
          filterOutUnchangedCodes(updateColumns, formParams, node.data)
        )
        .map((node) => node.data[pms_code_vetsuccess_id.field]);
      if (selectedPmsIds.length) {
        let timerId = setTimeout(function () {
          setIsRequest(true);
          gridRef.current.api.showLoadingOverlay();
        }, UPDATE_WAITING_TIME_LIMIT);
        let updateParams = {
          pms_code_vetsuccess_id: selectedPmsIds,
        };
        updateFunction({ ...updateParams, ...formParams }, jobId)
          .then((updatedResources) => {
            updateTableData(
              gridRef.current.api.getSelectedNodes(),
              updatedResources,
              availableCodeTags,
              updateColumns
            );
            dispatch(modalClosed());
          })
          .catch((error) => {
            modal
              ? dispatch(serverErrorInModal(parseError(error).description))
              : setErrorAlert({ error: error });
          })
          .finally(() => {
            clearLongRequest(timerId, setIsRequest, gridRef.current.api);
            changeMappedCodeCount();
          });
      } else {
        dispatch(modalClosed());
      }
    };
  }

  const updateCallback = (
    column,
    data,
    newValue,
    node,
    oldValue,
    undoAction
  ) => {
    //maybe someone used fill handle with unmappable ct/rc so use all
    if (!isPseudoeditableColumn(column)) {
      let formattedRequest = formatRequest(
        column,
        data,
        newValue,
        getMappableElements(availableCodeTags),
        allRevenueCategories,
        undoAction
      );
      if (formattedRequest) {
        let timerId = setTimeout(function () {
          setIsRequest(true);
          gridRef.current.api.showLoadingOverlay();
        }, UPDATE_WAITING_TIME_LIMIT);
        updateJobTableRow(formattedRequest, jobId)
          .then((response) => {
            updateTableData([node], response.data, availableCodeTags, [column]);
            if (isDosesColumn(column) && !undoAction) {
              advanceToNextRow(gridRef.current.api, node.rowIndex, column);
            }
          })
          .catch((error) => {
            setErrorAlert({ error: error });
            node.setDataValue(column, oldValue);
          })
          .finally(() => {
            changeMappedCodeCount();
            clearLongRequest(timerId, setIsRequest, gridRef.current.api);
          });
      }
    }
  };

  const onSortChanged = useCallback((event) => {
    event.api.deselectAll();
    event.api.paginationGoToFirstPage();
    setClearSortVisible(event.api.getColumnState().some((c) => c.sort));
    didUserConfigChangeCallback(event);
  }, []);

  const onToolPanelVisibleChanged = useCallback((event) => {
    setIsFilterPanelOpen(event.api.isToolPanelShowing());
  }, []);

  const onSortReset = useCallback(() => {
    gridRef.current.api.applyColumnState({
      defaultState: { sort: null },
    });
    gridRef.current.api.deselectAll();
    gridRef.current.api.paginationGoToFirstPage();
    setIsUserConfigChanged(true);
  }, [gridRef.current?.api]);

  const showTotalTime = useMemo(() => {
    return (
      jobDetails &&
      jobDetails.claimed_by === userInfo.id &&
      hoursEditableForUser(
        jobDetails.status,
        mappableJobStatuses,
        jobDetails.invoiced,
        jobDetails.rate_type
      )
    );
  }, [userInfo, jobDetails]);

  const onCellMouseOverHandler = useCallback(
    (e) =>
      !readOnly &&
      onCellMouseOver(
        e,
        previousMouseOverColumnRef,
        cellFocusedByKeyboardNavigationRef
      ),
    []
  );

  const rowClassRules = useMemo(
    () => ({
      '!bg-row-red': (params) =>
        params.node?.data?.verification_pipeline_status_id,
    }),
    []
  );

  const addCodeTagsHandler = useMemo(
    () =>
      getBatchUpdateHandler(batchUpdateJobCodeTagsAdd, [code_tags_ids.field]),
    []
  );

  const removeCodeTagsHandler = useMemo(
    () =>
      getBatchUpdateHandler(batchUpdateJobCodeTagsRemove, [
        code_tags_ids.field,
      ]),
    []
  );

  const replaceCodeTagsHandler = useMemo(
    () =>
      getBatchUpdateHandler(batchUpdateJobCodeTagsReplace, [
        code_tags_ids.field,
      ]),
    []
  );

  const updateRevenueCategoryHandler = useMemo(
    () =>
      getBatchUpdateHandler(updateJobCodeMappings, [
        revenue_category_id.field,
        verified.field,
      ]),
    []
  );

  const updateReviewStatusHandler = useMemo(
    () => getBatchUpdateHandler(updateJobCodeMappings, [review_status.field]),
    []
  );

  const updateFreeDosesHandler = useMemo(
    () => getBatchUpdateHandler(updateJobCodeMappings, [free_doses.field]),
    []
  );

  const updatePaidDosesHandler = useMemo(
    () => getBatchUpdateHandler(updateJobCodeMappings, [paid_doses.field]),
    []
  );

  const updateVerificationStatusHandler = useMemo(
    () =>
      getBatchUpdateHandler(updateJobCodeMappings, [
        verification_pipeline_status_id.field,
      ]),
    []
  );

  const didUserConfigChangeCallback = useCallback((e) => {
    didUserConfigChange(e, setIsUserConfigChanged);
  }, []);

  const resetDragEventsCallback = () => {
    resetDragEvents(tableRef, filterLayout, setIsUserConfigChanged);
  };

  const createFilterActionsPortal = () => {
    if (isTableReady && document.querySelector('.ag-tool-panel-wrapper')) {
      return createPortal(
        renderFilterActions(
          gridRef.current.api,
          isRequest,
          !isTableReady,
          resetJobMappingsFilters,
          setShowLeavePageConfirmationDialog,
          defaultFilterModel
        ),
        document.querySelector('.ag-tool-panel-wrapper')
      );
    }
  };

  const createFilterActions = useMemo(
    () => createFilterActionsPortal(),
    [gridRef.current?.api, isTableReady, isRequest]
  );

  const getJobItemsHandler = useMemo(
    () =>
      getJobItems(
        userInfo,
        jobDetails,
        downloadCSVFile,
        markJobCompleteMapping,
        markJobCompleted,
        markJobInvoiced,
        unhideJob,
        viewJobDetails
      ),
    [jobDetails]
  );

  const createActionsDropdownPortal = () => {
    if (
      isTableReady &&
      jobDetails &&
      userInfo &&
      document.querySelector('#actions-dropdown-placeholder')
    ) {
      return createPortal(
        <>
          <MappingDropdown items={getJobItemsHandler} />
          <JobMappingsStatusIcons
            errorMessage={jobDetails.error_message}
            readOnly={readOnly}
            invoiced={jobDetails.invoiced}
            status={jobDetails.status}
            hidden={jobDetails.hidden_from_mapper}
          />
          {isAdmin(userInfo) && jobDetails.claimed_by && (
            <UserIdIcon
              userInfo={getElementById(usersDetails, jobDetails.claimed_by)}
            />
          )}
          {isClearSortVisible && (
            <OutlineIconButton
              text="Clear Sort"
              outlineColor="border-gray-vs"
              bgClass="bg-white"
              padding="px-2 py-1"
              margin="mx-5"
              icon={clearSortIcon}
              widthClass="w-[110px]"
              onClick={onSortReset}
            />
          )}
        </>,
        document.querySelector('#actions-dropdown-placeholder')
      );
    }
  };

  const createActionsDropdown = useMemo(
    () => createActionsDropdownPortal(),
    [gridRef.current?.api, isTableReady, jobDetails, isClearSortVisible]
  );

  const createUserTableConfigPortal = () => {
    if (isTableReady && document.querySelector('#right-section-placeholder')) {
      return createPortal(
        <>
          <UserTableConfiguration
            configType={USER_CONFIG_TYPE_JOB_MAPPINGS}
            maxLayouts={10}
            gridApi={gridRef.current.api}
            setCurrentPageSize={setCurrentPageSize}
            filterLayout={filterLayout}
            defaultFilterLayout={defaultFilterLayout}
            configs={userTableConfigurations}
            activeConfig={activeUserTableConfiguration}
            isEdited={isUserConfigChanged}
            setIsEdited={setIsUserConfigChanged}
            resetDragEvents={resetDragEventsCallback}
            setErrorAlert={setErrorAlert}
          />
        </>,
        document.querySelector('#right-section-placeholder')
      );
    }
  };

  const createUserTableConfig = useMemo(
    () => createUserTableConfigPortal(),
    [
      gridRef.current?.api,
      isTableReady,
      isUserConfigChanged,
      userTableConfigurations,
    ]
  );

  return (
    <>
      <div
        ref={tableRef}
        className={`ag-theme-alpine${isTableReady ? '' : ' data-loading'}`}
        style={{ height: '100%', width: '100%' }}
      >
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          gridOptions={gridOptions}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          onGridSizeChanged={onGridSizeChanged}
          pagination={true}
          paginationPageSize={currentPageSize}
          paginationPageSizeSelector={false}
          paginationNumberFormatter={paginationNumberFormatter}
          rowSelection={'multiple'}
          rowMultiSelectWithClick={false}
          alwaysMultiSort={true}
          tooltipShowDelay={500}
          onFilterChanged={onFilterChanged}
          onFirstDataRendered={onFirstDataRendered}
          undoRedoCellEditing={!readOnly}
          undoRedoCellEditingLimit={readOnly ? 0 : UNDO_REDO_EDIT_LIMIT}
          onModelUpdated={updateUndoState}
          getContextMenuItems={getContextMenuItemsCallback}
          onCellContextMenu={onCellContextMenu}
          onCellKeyDown={onCellKeyDownCallback}
          getRowId={getRowId}
          rowClassRules={rowClassRules}
          // Can we enable animateRows?
          animateRows={false}
          headerHeight={45}
          rowHeight={27}
          onCellEditingStopped={onCellEditingStoppedCallback}
          onRangeSelectionChanged={onRangeSelectionChangedCallback}
          enableRangeSelection
          onDragStopped={onDragStopped}
          onDragStarted={onDragStarted}
          suppressMultiRangeSelection
          suppressColumnMoveAnimation={true}
          enableFillHandle
          fillHandleDirection="y"
          fillOperation={(params) =>
            fillOperation(
              setIsRequest,
              params,
              fillHandleDataRef,
              getMappableElements(availableCodeTags),
              allRevenueCategories,
              updateJobCodeMappings,
              setErrorAlert,
              changeMappedCodeCount,
              jobId
            )
          }
          stopEditingWhenCellsLoseFocus={true}
          sideBar={sideBar}
          onCellMouseOver={onCellMouseOverHandler}
          onCellValueChanged={onCellValueChangedCallback}
          onPaginationChanged={onPaginationChanged}
          overlayLoadingTemplate={
            '<div aria-live="polite" aria-atomic="true" class="loader" aria-label="loading"></div>'
          }
          rowData={rowData}
          onSortChanged={onSortChanged}
          onToolPanelVisibleChanged={onToolPanelVisibleChanged}
          rowBuffer={15}
          onColumnResized={didUserConfigChangeCallback}
          onColumnVisible={didUserConfigChangeCallback}
          onColumnMoved={didUserConfigChangeCallback}
        ></AgGridReact>
        <PageSize
          currentPageSize={currentPageSize}
          setPageSize={setPageSize}
          largePageSize={true}
        />
        {jobDetails && (
          <JobCodeCountInfo
            totalCodeCount={jobDetails.unverified_code_count}
            mappedCodeCount={mappedCodeCount}
          />
        )}
        {showTotalTime && (
          <span className="row-per-page-container ml-96">
            <div className="mr-2">Total time:</div>
            <MinutesSpent
              jobDetails={jobDetails}
              setErrorAlert={setErrorAlert}
              totalTimeClassName="font-bold"
            />
          </span>
        )}
        {createProtocolInfo}
        {createUndoActions}
        {createActionsDropdown}
        {createUserTableConfig}
        {createFilterActions}
        {createFilterStatus}
      </div>
      <div>
        <BatchActionsModals
          onDialogCancel={onDialogCancel}
          addCodeTagsHandler={addCodeTagsHandler}
          removeCodeTagsHandler={removeCodeTagsHandler}
          replaceCodeTagsHandler={replaceCodeTagsHandler}
          updateRevenueCategoryHandler={updateRevenueCategoryHandler}
          updateReviewStatusHandler={updateReviewStatusHandler}
          updateFreeDosesHandler={updateFreeDosesHandler}
          updatePaidDosesHandler={updatePaidDosesHandler}
          updateVerificationStatusHandler={updateVerificationStatusHandler}
          filtersPanelOpen={isFilterPanelOpen}
          availableCodeTags={availableCodeTags}
        />
        <ConfirmationModal
          title="You're about to leave this page"
          isOpen={showPrompt}
          onButtonClick={confirmNavigation}
          onClose={cancelNavigation}
          buttonText="Yes, leave"
          text="Applied filters will get reset. Are you sure you want to leave?"
        />
      </div>
    </>
  );
}
