import axios from 'axios';
import axiosRetry from 'axios-retry';
import { logout } from '../features/user/userActions';
import { getAuthorizationHeader, refreshToken } from './userServices';
import { updateTokens } from '../features/user/userSlice';
import { tokenExpired } from '../utils/helper';
import { logger } from './logger';

let store;

const axiosSecureInstance = axios.create({});
const axiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});
const axiosSecureInstanceFileUpload = axios.create({});
const axiosUploadFileFormInstance = axios.create({
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export const injectStore = (_store) => {
  axiosSecureInstance.defaults.baseURL = import.meta.env.VITE_SERVER_API_URL;
  axiosInstance.defaults.baseURL = import.meta.env.VITE_SERVER_API_URL;
  axiosSecureInstanceFileUpload.defaults.baseURL =
    import.meta.env.VITE_SERVER_API_URL;
  axiosUploadFileFormInstance.defaults.baseURL =
    import.meta.env.VITE_SERVER_API_URL;
  store = _store;
};

axiosRetry(axiosInstance, {
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: axiosRetry.isNetworkError,
});

axiosRetry(axiosSecureInstance, {
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: axiosRetry.isNetworkError,
});

axiosSecureInstance.interceptors.request.use((config) => {
  config.headers.authorization = store.getState().user.token;
  return config;
});

axiosSecureInstanceFileUpload.interceptors.request.use((config) => {
  config.headers.authorization = store.getState().user.token;
  return config;
});

axiosSecureInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error?.response?.status === 401 &&
      store.getState().user.refreshTokenInProgress
    ) {
      // refresh token is in progress - retry request
      return axios(originalRequest);
    }
    if (
      error?.response?.status === 401 &&
      originalRequest.headers.authorization !== store.getState().user.token
    ) {
      //token was refreshed in meantime - use the one from the state
      originalRequest.headers.authorization = getAuthorizationHeader(
        store.getState().user.token
      );
      return axios(originalRequest);
    }
    if (error?.response?.status === 401) {
      if (
        !tokenExpired(store.getState().user.refreshTokenExpiry) &&
        !store.getState().user.refreshTokenInProgress
      ) {
        // we can't dispatch here refresh tokens since we need to resend old request with response that we get (new access token)
        // if we don't resend, user will get error message for original req
        try {
          const { data } = await refreshToken(
            store.getState().user.refreshToken
          );
          store.dispatch(updateTokens(data));
          //set new token to original request and retry request
          originalRequest.headers.Authorization = getAuthorizationHeader(
            data.token
          );
          return axios(originalRequest);
        } catch (err) {
          // in case refreshing fails for any reason logout user - and log error
          logger.catchError(error);
          store.dispatch(logout());
        }
      } else {
        store.dispatch(logout());
      }
    }
    return Promise.reject(error);
  }
);

axiosSecureInstanceFileUpload.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      store.dispatch(logout());
    }
    return Promise.reject(error);
  }
);

export {
  axiosSecureInstance,
  axiosInstance,
  axiosSecureInstanceFileUpload,
  axiosUploadFileFormInstance,
};
