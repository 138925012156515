import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { ResetFilterButton } from './ResetFilterButton';
import 'react-datepicker/dist/react-datepicker.css';
import { DateInput } from './DateInput';
import moment from 'moment';
import { applyFiltersOnKeyPress } from '../../../helpers/tableFiltersHelper';
import { defaultNumberCompareOperators } from '../../../constants/constants';

const filterOperators = defaultNumberCompareOperators;
export default forwardRef((props, ref) => {
  const [filterDate1, setFilterDate1] = useState(null);
  const [filterOperator1, setFilterOperator1] = useState(filterOperators[0]);
  const [filterDate2, setFilterDate2] = useState(null);
  const [filterOperator2, setFilterOperator2] = useState(filterOperators[0]);

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        const { api, colDef, column, columnApi, context } = props;
        const { node } = params;

        let passed = false;
        const value = props.valueGetter({
          api,
          colDef,
          column,
          columnApi,
          context,
          data: node.data,
          getValue: (field) => node.data[field],
          node,
        });
        passed =
          filterPass(value, filterOperator1, filterDate1) &&
          (!filterDate2 || filterPass(value, filterOperator2, filterDate2));

        return passed;
      },

      isFilterActive() {
        return filterDate1 != null && filterDate1 !== '';
      },

      getModel() {
        if (!this.isFilterActive()) {
          return null;
        }

        return {
          values: getFilter(filterDate1, filterOperator1).concat(
            getFilter(filterDate2, filterOperator2)
          ),
          type: 'date',
          label: props.colDef.headerName,
        };
      },

      setModel(model) {
        setFilterDate1(
          model === null || model === undefined ? null : model.filter1.value
        );
        setFilterDate2(
          model === null || model === undefined ? null : model.filter2.value
        );
      },
    };
  });

  useEffect(() => {
    if (!filterDate1) {
      setFilterDate2(null);
      setFilterOperator2(filterOperators[0]);
    }
    props.filterModifiedCallback();
  }, [filterDate1]);

  const filterPass = (value, filterOperator, filterDateString) => {
    const filterDate = moment(filterDateString).format('YYYY-MM-DD');
    switch (filterOperator.value) {
      case 'eq':
        return value === filterDate;
      case 'lt':
        return value < filterDate;
      case 'lte':
        return value <= filterDate;
      case 'gt':
        return value > filterDate;
      case 'gte':
        return value >= filterDate;
      default:
        return false;
    }
  };

  const resetFilter = () => {
    if (filterDate1) {
      setFilterDate1(null);
      setFilterOperator1(filterOperators[0]);
      setFilterDate2(null);
      setFilterOperator2(filterOperators[0]);
    }
  };

  const getFilter = (dateStr, operator) => {
    if (dateStr) {
      return [
        {
          value: moment(dateStr).format('YYYY-MM-DD'),
          operator: operator.value,
          operatorLabel: operator.label.toLowerCase(),
        },
      ];
    } else {
      return [];
    }
  };

  return (
    <div
      id="table-date-filter"
      onKeyDown={({ key }) => {
        applyFiltersOnKeyPress(key, props);
      }}
    >
      <ResetFilterButton onReset={resetFilter} />
      <DateInput
        options={filterOperators}
        selectValue={filterOperator1}
        onSelectChange={setFilterOperator1}
        inputValue={filterDate1}
        onInputChange={setFilterDate1}
        id="table-date-filter-wrapper1"
      />
      {filterDate1 && (
        <>
          <div className="text-center">AND</div>
          <DateInput
            options={filterOperators}
            selectValue={filterOperator2}
            onSelectChange={setFilterOperator2}
            inputValue={filterDate2}
            onInputChange={setFilterDate2}
            id="table-date-filter-wrapper2"
          />
        </>
      )}
    </div>
  );
});
