import {
  GROUP_RESOURCE_TYPE,
  PRACTICE_RESOURCE_TYPE,
} from '../constants/constants';

export function getElementById(elements, id) {
  return elements.find((element) => element.id === id);
}

export function getUndoRedoShortcut(action) {
  const os = getOS();
  if (os === 'MacOS' || os === 'iOS') {
    return (
      <>
        {action.label} (<span>{action.macKeyboardKeys.join(' + ')})</span>
      </>
    );
  } else if (os === 'Windows' || os === 'Linux' || os === 'Android') {
    return `${action.label} (${action.winKeyboardKeys.join(' + ')})`;
  } else {
    return '';
  }
}

function getOS() {
  const userAgent = navigator.userAgent;

  if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
    return 'MacOS';
  } else if (/iPhone|iPad|iPod/.test(userAgent)) {
    return 'iOS';
  } else if (/Win32|Win64|Windows|WinCE/.test(userAgent)) {
    return 'Windows';
  } else if (/Android/.test(userAgent)) {
    return 'Android';
  } else if (/Linux/.test(userAgent)) {
    return 'Linux';
  } else {
    return 'Unknown';
  }
}

export function isGroupResourceType(resourceType) {
  return resourceType === GROUP_RESOURCE_TYPE;
}

export function isPracticeResourceType(resourceType) {
  return resourceType === PRACTICE_RESOURCE_TYPE;
}

export function refreshPage() {
  window.location.reload();
}
