import { Fragment, memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Menu, Transition } from '@headlessui/react';
import { usePopper } from 'react-popper';
import SaveLayoutForm from './modals/SaveLayoutForm';
import FormModal from '../common/FormModal';
import ConfirmationModal from '../common/ConfirmationModal';
import {
  editUserTableConfiguration,
  removeUserTableConfiguration,
} from '../../features/user-configuration/userConfigActions';
import { mapFilterLayout } from '../../helpers/tableFiltersHelper';
import plusIcon from '../../assets/icons/plus.png';
import minusIcon from '../../assets/icons/minus.png';
import { DEFAULT_PAGE_SIZE } from '../../constants/constants';

const UserTableConfiguration = memo(function UserTableConfiguration({
  configType,
  maxLayouts,
  gridApi,
  setCurrentPageSize,
  filterLayout,
  defaultFilterLayout,
  configs,
  activeConfig,
  isEdited,
  setIsEdited,
  resetDragEvents,
  setErrorAlert,
}) {
  const dispatch = useDispatch();

  const layouts = configs.filter((c) => c.configType === configType);
  const [saveLayoutOpen, setSaveLayoutOpen] = useState(false);
  const [overrideLayout, setOverrideLayout] = useState(null);
  const [deleteLayout, setDeleteLayout] = useState(null);
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const [displayTooltip, setDisplayTooltip] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [-62, 10],
        },
      },
    ],
  });

  useEffect(() => {
    if (activeConfig) {
      gridApi.applyColumnState({
        state: activeConfig.configuration.columnState,
        applyOrder: true,
      });
    }
  }, []);

  const resetTableState = () => {
    gridApi.resetColumnState();
    setCurrentPageSize(DEFAULT_PAGE_SIZE);
    const filtersToolPanel = gridApi.getToolPanelInstance('filters');
    filtersToolPanel.setFilterLayout(mapFilterLayout(defaultFilterLayout));
    filterLayout.current = defaultFilterLayout;
    resetDragEvents();
    setIsEdited(false);
  };

  const setUserConfig = async (config) => {
    gridApi.applyColumnState({
      state: config.configuration.columnState,
      applyOrder: true,
    });
    setCurrentPageSize(config.configuration.pageSize || DEFAULT_PAGE_SIZE);
    const filtersToolPanel = gridApi.getToolPanelInstance('filters');
    filtersToolPanel.setFilterLayout(
      mapFilterLayout(config.configuration.filterOrder)
    );
    filterLayout.current = config.configuration.filterOrder;
    resetDragEvents();
    setIsEdited(false);
    try {
      await dispatch(
        editUserTableConfiguration({
          id: config.id,
          data: {
            active: true,
          },
        })
      ).unwrap();
      onFinish();
    } catch (error) {
      setErrorAlert({ parsedError: error });
    }
  };

  const resetUserConfig = async () => {
    resetTableState();
    if (activeConfig) {
      try {
        await dispatch(
          editUserTableConfiguration({
            id: activeConfig.id,
            data: {
              active: false,
            },
          })
        ).unwrap();
        onFinish();
      } catch (error) {
        setErrorAlert({ parsedError: error });
      }
    }
  };

  const updateLayout = async () => {
    try {
      await dispatch(
        editUserTableConfiguration({
          id: layouts.find((config) => config.name === overrideLayout.name).id,
          data: {
            configuration: overrideLayout.config,
            active: true,
          },
        })
      ).unwrap();
      setOverrideLayout(null);
      setIsEdited(false);
    } catch (error) {
      setErrorAlert({ parsedError: error });
    }
  };

  const removeLayout = async () => {
    try {
      const activeDeleted = activeConfig?.id === deleteLayout.id;
      await dispatch(removeUserTableConfiguration(deleteLayout.id)).unwrap();
      setDeleteLayout(null);
      if (activeDeleted) {
        resetTableState();
      }
    } catch (error) {
      setErrorAlert({ parsedError: error });
    }
  };

  const onFinish = () => {
    setSaveLayoutOpen(false);
    setIsEdited(false);
  };

  return (
    <>
      <Menu as="div" className="relative inline-block w-full text-left">
        <Menu.Button
          className="inline-flex items-center justify-between w-full px-3 py-1 border rounded gap-x-1.5 text-14"
          data-testid="user-table-config-menu-button"
        >
          {({ open }) => (
            <>
              <div className="truncate" title={activeConfig?.name}>{`${
                isEdited ? '(edited) ' : ''
              } ${activeConfig?.name || 'Default Layout'}`}</div>
              <div
                className={`arrow ${open ? 'up' : 'down'} border-purple-vs`}
              />
            </>
          )}
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="absolute right-0 w-full z-10 mt-2 rounded bg-white border-[1px]"
            anchor="top start"
          >
            <div className="border-b-[1px]">
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={`flex items-center justify-between ${
                      !isEdited
                        ? 'pointer-events-none cursor-default opacity-25'
                        : 'cursor-pointer'
                    } ${active && 'bg-neutral-100'}`}
                  >
                    <div
                      className={'block px-4 py-2.5 text-13 font-medium'}
                      onClick={() => setSaveLayoutOpen(true)}
                    >
                      Save custom layout
                    </div>
                    <div className="block box-content w-6 pr-4 text-13 font-medium">
                      <img src={plusIcon} alt="add-icon" />
                    </div>
                  </div>
                )}
              </Menu.Item>
            </div>
            <div className="cursor-pointer">
              <Menu.Item>
                {({ active }) => (
                  <span
                    className={`block px-4 py-2.5 text-13 font-medium ${
                      active && 'bg-neutral-100'
                    } ${!activeConfig && 'bg-neutral-200'}`}
                    onClick={resetUserConfig}
                  >
                    Default layout
                  </span>
                )}
              </Menu.Item>
            </div>
            {layouts.map((item) => (
              <div key={item.name} className="cursor-pointer">
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={`flex items-center justify-between ${
                        active && 'bg-neutral-100'
                      } ${item.id === activeConfig?.id && 'bg-neutral-200'}`}
                    >
                      <div
                        className={
                          'flex-auto block px-4 py-2.5 text-13 font-medium truncate'
                        }
                        onClick={() => setUserConfig(item)}
                        title={item.name}
                      >
                        {item.name}
                      </div>
                      <div className="shrink-0 w-10">
                        {active && (
                          <>
                            <div
                              className="pr-4"
                              onClick={() => setDeleteLayout(item)}
                              ref={setReferenceElement}
                              onMouseEnter={() => setDisplayTooltip(true)}
                              onMouseLeave={() => setDisplayTooltip(false)}
                            >
                              <img src={minusIcon} alt="remove-icon" />
                            </div>
                            <div
                              ref={setPopperElement}
                              style={styles.popper}
                              {...attributes.popper}
                              className={`popper-delete-tooltip ${
                                displayTooltip ? '' : 'hidden'
                              }`}
                            >
                              <div id="arrow" data-popper-arrow></div>
                              <p className="whitespace-normal">Remove layout</p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Menu.Item>
              </div>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
      <FormModal
        openState={saveLayoutOpen}
        onClose={() => setSaveLayoutOpen(false)}
        title="Save Layout"
        form={
          <SaveLayoutForm
            configType={configType}
            maxLayouts={maxLayouts}
            gridApi={gridApi}
            filterLayout={filterLayout}
            layouts={layouts}
            activeConfigName={activeConfig?.name}
            setOverrideLayout={setOverrideLayout}
            onFinish={onFinish}
            onCancel={() => setSaveLayoutOpen(false)}
          />
        }
      />
      {overrideLayout && (
        <ConfirmationModal
          title="Are you sure you want to override?"
          text={`The layout called ${overrideLayout.name} already exists. Are you sure you want to override the existing layout?`}
          buttonText="Yes, Override"
          operationType="warning"
          onButtonClick={updateLayout}
          onClose={() => setOverrideLayout(null)}
          isOpen={!!overrideLayout}
          overrideDefaultPosition="mt-4"
        />
      )}
      {deleteLayout && (
        <ConfirmationModal
          title="Are you sure?"
          text={`Are you sure you want to delete the layout named ${deleteLayout.name}? This action is irreversible.`}
          buttonText="Yes, Delete"
          operationType="danger"
          onButtonClick={removeLayout}
          onClose={() => setDeleteLayout(null)}
          isOpen={!!deleteLayout}
          overrideDefaultPosition="mt-4"
        />
      )}
    </>
  );
});

export default UserTableConfiguration;
