import { memo } from 'react';
import {
  JOB_STATUS_MAPPING_COMPLETE,
  MAX_CODE_COUNT_LIMIT_ERROR,
  MAX_CODE_COUNT_LIMIT_ERROR_TITLE,
} from '../../constants/jobConstants';
import eyeIcon from '../../assets/icons/eye.svg';
import readyIcon from '../../assets/icons/ready.svg';
import invoiceIcon from '../../assets/icons/invoiced_icon.svg';
import hiddenIcon from '../../assets/icons/hidden.svg';
import refreshErrorIcon from '../../assets/icons/refresh_error_icon.svg';

const JobMappingsStatusIcons = memo(function JobMappingsStatusIcons({
  errorMessage,
  readOnly,
  invoiced,
  status,
  hidden,
}) {
  return (
    <div className="flex items-center text-sm">
      {readOnly && (
        <div className="group relative">
          <img className="ml-4" src={eyeIcon} alt="eye-icon" />
          <div className="status-tooltip">
            <div className="status-tooltip-arrow" />
            <div className="font-semibold mb-1.5">Read-only mode</div>
            <div>You’re not allowed to edit this job.</div>
          </div>
        </div>
      )}
      {hidden && (
        <div className="group relative">
          <img className="ml-4" src={hiddenIcon} alt="hidden-icon" />
          <div className="status-tooltip">
            <div className="status-tooltip-arrow" />
            <div>Hidden from Mapper</div>
          </div>
        </div>
      )}
      {errorMessage === MAX_CODE_COUNT_LIMIT_ERROR && (
        <div className="group relative">
          <img
            className="ml-4"
            src={refreshErrorIcon}
            alt="refresh-error-icon"
          />
          <div className="status-tooltip">
            <div className="font-semibold mb-1.5">
              {MAX_CODE_COUNT_LIMIT_ERROR_TITLE}
            </div>
            <div className="status-tooltip-arrow" />
            <div>
              This job has reached the limit of
              <br />
              20.000 codes. Thus, new codes
              <br />
              will not be added when the job is
              <br />
              refreshed.
            </div>
          </div>
        </div>
      )}
      {status === JOB_STATUS_MAPPING_COMPLETE && (
        <div className="group relative">
          <img className="ml-4" src={readyIcon} alt="ready-icon" />
          <div className="status-tooltip">
            <div className="font-semibold mb-1.5">Mapping complete</div>
            <div className="status-tooltip-arrow" />
            <div>
              This job is ready for an admin to
              <br />
              review and mark as complete.
            </div>
          </div>
        </div>
      )}
      {invoiced && (
        <div className="group relative">
          <img className="ml-4" src={invoiceIcon} alt="invoiced-icon" />
          <div className="status-tooltip">
            <div className="status-tooltip-arrow" />
            <div className="font-semibold mb-1.5">Invoiced</div>
            <div>This job is invoiced.</div>
          </div>
        </div>
      )}
    </div>
  );
});

export default JobMappingsStatusIcons;
