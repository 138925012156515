import { createSlice } from '@reduxjs/toolkit';
import {
  jobDetailsEditSaved,
  jobNotesSaved,
  jobMappedTimeSaved,
  jobPracticeTypeSaved,
} from '../job/jobActions';
import { loadUsers } from '../table/tableActions';
import { getActiveUserElements } from '../../helpers/selectHelper';
import { resetState } from '../user/userActions';

const initialState = {
  refreshJobsTable: false,
  activeUsersOnly: null,
};

export const jobsTableSlice = createSlice({
  name: 'jobsTableSlice',
  initialState: initialState,
  reducers: {
    jobsTableRefreshed: (state) => {
      state.refreshJobsTable = false;
    },
  },
  extraReducers: {
    [jobDetailsEditSaved]: (state) => {
      state.refreshJobsTable = true;
    },
    [jobNotesSaved]: (state) => {
      state.refreshJobsTable = true;
    },
    [jobMappedTimeSaved]: (state) => {
      state.refreshJobsTable = true;
    },
    [jobPracticeTypeSaved]: (state) => {
      state.refreshJobsTable = true;
    },
    [loadUsers.fulfilled]: (state, { payload }) => {
      state.activeUsersOnly = getActiveUserElements(payload);
    },
    [resetState]: () => initialState,
  },
});

export const { jobsTableRefreshed } = jobsTableSlice.actions;
export default jobsTableSlice.reducer;
