import { useEffect, useRef, useImperativeHandle, forwardRef } from 'react';

const ToggleCellEditor = forwardRef((props, ref) => {
  const refInput = useRef(null);

  useEffect(() => {
    props.stopEditing();
  });

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return !props.value;
      },

      isCancelAfterEnd() {
        return false;
      },
    };
  });

  return (
    <div ref={refInput} className="hidden">
      {props.value}
    </div>
  );
});

ToggleCellEditor.displayName = 'ToggleCellEditor';

export default ToggleCellEditor;
