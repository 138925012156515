import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Select from 'react-select';
import { ResetFilterButton } from './ResetFilterButton';
import { SelectInput } from './SelectInput';
import { applyFiltersOnKeyPress } from '../../../helpers/tableFiltersHelper';
import arrowDown from '../../../assets/icons/arrow_downsvg.svg';

const logicalOperators = [
  { value: 'and', label: 'AND' },
  { value: 'or', label: 'OR' },
];

export default forwardRef((props, ref) => {
  const maxLength = props.colDef.filterParams?.maxTextLength || null;
  const maxNumberOfFilters = props.colDef.filterParams.maxNumberOfFilters || 3;
  const defaultOperator = props.colDef.filterParams.operators[0];
  const hasOr = props.colDef.filterParams.hasOr;

  const [filterValues, setFilterValues] = useState(Array(maxNumberOfFilters));
  const [activeFilterNumber, setActiveFilterNumber] = useState(1);
  const [logicalOperator, setLogicalOperator] = useState(logicalOperators[0]);
  const [filterOperators, setFilterOperators] = useState(
    Array(maxNumberOfFilters).fill(defaultOperator)
  );

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        const { api, colDef, column, columnApi, context } = props;
        const { node } = params;

        const value = props.valueGetter({
          api,
          colDef,
          column,
          columnApi,
          context,
          data: node.data,
          getValue: (field) => node.data[field],
          node,
        });
        const valueLowerCase = value?.toString().toLowerCase() || '';
        const filterValuesArray = Object.values(filterValues).filter(
          (value) => value !== null && value !== ''
        );
        if (hasOr && logicalOperator === logicalOperators[1]) {
          return filterValuesArray.some((filterValue, i) =>
            filterPass(valueLowerCase, filterOperators[i], filterValue)
          );
        }
        return filterValuesArray.every((filterValue, i) =>
          filterPass(valueLowerCase, filterOperators[i], filterValue)
        );
      },

      isFilterActive() {
        return filterValues[0] != null && filterValues[0] !== '';
      },

      getModel() {
        if (!this.isFilterActive()) {
          return null;
        }

        const values = [];
        for (
          let filterIndex = 0;
          filterIndex < maxNumberOfFilters;
          filterIndex++
        ) {
          addFilter(
            values,
            filterValues[filterIndex],
            filterOperators[filterIndex]
          );
        }

        return {
          values: values,
          type: 'text',
          label: props.colDef.headerName,
        };
      },
      //TODO: check when is this being called
      setModel(model) {
        let newFilterValues = { ...filterValues };
        for (
          let filterIndex = 0;
          filterIndex < maxNumberOfFilters;
          filterIndex++
        ) {
          newFilterValues[filterIndex] =
            model === null || model === undefined
              ? null
              : model[`filter${filterIndex}`].value;
        }
        setFilterValues(newFilterValues);
      },
    };
  });

  useEffect(() => {
    props.filterModifiedCallback();
  }, [filterValues]);

  useEffect(() => {
    let newFilterValues = { ...filterValues };
    let newFilterOperators = { ...filterOperators };
    for (
      let filterIndex = activeFilterNumber;
      filterIndex < maxNumberOfFilters;
      filterIndex++
    ) {
      newFilterValues[filterIndex] = null;
      newFilterOperators[filterIndex] = defaultOperator;
    }
    setFilterValues(newFilterValues);
    setFilterOperators(newFilterOperators);
  }, [activeFilterNumber]);

  useEffect(() => {
    for (let filterIndex = 0; filterIndex < maxNumberOfFilters; filterIndex++) {
      if (!filterValues[filterIndex]) {
        setActiveFilterNumber(filterIndex + 1);
        return;
      }
    }
  }, [filterValues]);

  const filterPass = (valueLowerCase, filterOperator, filterText) => {
    const filterTextLowerCase = filterText.toLowerCase();
    switch (filterOperator.value) {
      case 'contains':
        return valueLowerCase.indexOf(filterTextLowerCase) >= 0;
      case 'notContains':
        return valueLowerCase.indexOf(filterTextLowerCase) === -1;
      case 'startsWith':
        return valueLowerCase.indexOf(filterTextLowerCase) === 0;
      case 'equals':
        return valueLowerCase === filterTextLowerCase;
      case 'endsWith': {
        const index = valueLowerCase.lastIndexOf(filterTextLowerCase);
        return (
          index >= 0 &&
          index === valueLowerCase.length - filterTextLowerCase.length
        );
      }
      default:
        return false;
    }
  };

  const resetFilter = () => {
    setFilterOperators(Array(maxNumberOfFilters).fill(defaultOperator));
    setFilterValues(Array(maxNumberOfFilters));
  };

  const addFilter = (values, text, operator) => {
    if (text) {
      values.push({
        value: text,
        operator: operator.value,
        operatorLabel: operator.label.toLowerCase(),
      });
    }
  };

  const updateFilterValues = (value, index) => {
    let newFilterValues = { ...filterValues };
    newFilterValues[index] = value;
    setFilterValues(newFilterValues);
  };

  const updateFilterOperator = (operator, index) => {
    let newFilterOperators = { ...filterOperators };
    newFilterOperators[index] = operator;
    setFilterOperators(newFilterOperators);
  };

  const filters = [];
  filters.push(
    <div key={'filterTextOptionDiv0'}>
      <SelectInput
        index="0"
        options={props.colDef.filterParams.operators}
        selectValue={filterOperators[0]}
        onSelectChange={updateFilterOperator}
        inputValue={filterValues[0]}
        onInputChange={updateFilterValues}
        maxLength={maxLength}
      />
    </div>
  );
  for (let i = 1; i < activeFilterNumber; i++) {
    if (filterValues[i - 1]) {
      filters.push(
        <div key={`filterTextOptionDiv${i}`}>
          {hasOr && i === 1 ? (
            <Select
              options={logicalOperators}
              value={logicalOperator}
              onChange={setLogicalOperator}
              className="w-20 m-auto p-2.5"
              menuPosition="fixed"
              components={{
                DropdownIndicator: () => (
                  <img src={arrowDown} className="w-5 pr-1" />
                ),
                IndicatorSeparator: () => null,
              }}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  minHeight: '30px',
                }),
                valueContainer: (baseStyles) => ({
                  ...baseStyles,
                  padding: '2px 0 2px 6px',
                  textAlign: 'center',
                }),
                menuList: (baseStyles) => ({
                  ...baseStyles,
                  textAlign: 'center',
                }),
              }}
            />
          ) : (
            <div className="text-center">{logicalOperator.label}</div>
          )}
          <SelectInput
            index={i}
            options={props.colDef.filterParams.operators}
            selectValue={filterOperators[i]}
            onSelectChange={updateFilterOperator}
            inputValue={filterValues[i]}
            onInputChange={updateFilterValues}
            maxLength={maxLength}
          />
        </div>
      );
    }
  }

  return (
    <div
      id="table-text-filter"
      onKeyDown={({ key }) => applyFiltersOnKeyPress(key, props)}
    >
      <ResetFilterButton onReset={resetFilter} />
      {filters}
    </div>
  );
});
