import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Select from 'react-select';
import { ResetFilterButton } from './ResetFilterButton';
import { applyFiltersOnKeyPress } from '../../../helpers/tableFiltersHelper';
import { CustomAsyncMultiSelect } from './CustomAsyncMultiSelect';
import { CustomMultiSelect } from './CustomMultiSelect';
import {
  AND_FILTER_TYPE,
  CONTAINS_ALL_FILTER_VALUE,
  CONTAINS_ANY_FILTER_VALUE,
  CONTAINS_ONLY_FILTER_VALUE,
  DOES_NOT_CONTAIN_FILTER_VALUE,
  GROUPS_CONTAINS_ALL_FILTER_VALUE,
  GROUPS_CONTAINS_ANY_FILTER_VALUE,
  GROUPS_DOES_NOT_CONTAIN_FILTER_VALUE,
  OR_FILTER_TYPE,
} from '../../../constants/constants';

export default forwardRef((props, ref) => {
  const defaultValue = () => {
    return props.colDef.filterParams && props.colDef.filterParams.defaultValue
      ? props.colDef.filterParams.defaultValue
      : [];
  };
  const defaultOperator = props.colDef.filterParams.operators
    ? props.colDef.filterParams.operators[0]
    : null;
  const filterType = props.colDef.filterParams.filterType || OR_FILTER_TYPE;
  const [filterValues, setFilterValues] = useState(defaultValue());
  const [filterOperator, setFilterOperator] = useState(defaultOperator);

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        const { api, colDef, column, columnApi, context } = props;
        const { node } = params;

        const value = props.colDef.filterParams?.field
          ? params.data[props.colDef.filterParams.field]
          : props.valueGetter({
              api,
              colDef,
              column,
              columnApi,
              context,
              data: node.data,
              getValue: (field) => node.data[field],
              node,
            });

        return props.colDef.filterParams?.inverted
          ? filterPassInverted(value, filterValues)
          : filterPass(value, filterValues);
      },

      isFilterActive() {
        return filterValues.length;
      },

      getModel() {
        if (!this.isFilterActive()) {
          return null;
        }

        return props.operators
          ? {
              values: filterValues.map((v) => v.id),
              valueLabels: filterValues.map((v) => v.label),
              type: 'setWithOperators',
              operator: filterOperator.value,
              operatorLabel: filterOperator.label.toLowerCase(),
              label: props.colDef.headerName,
            }
          : {
              values: filterValues.map((v) => v.id),
              valueLabels: filterValues.map((v) => v.label),
              type: 'set',
              label: props.colDef.headerName,
            };
      },

      setModel(model) {
        if (model === null || model === undefined) {
          resetFilter();
        } else {
          setFilterValues(model.values);
        }
      },
    };
  });

  useEffect(() => {
    props.filterModifiedCallback();
  }, [filterValues]);

  const filterPass = (value, filterValues) => {
    //multivalue column - job mappings table - for code tags
    if (Array.isArray(value)) {
      if (defaultOperator) {
        switch (filterOperator.value) {
          case CONTAINS_ALL_FILTER_VALUE:
            return filterValues.every((filterValue) =>
              value.includes(filterValue.id)
            );
          case CONTAINS_ONLY_FILTER_VALUE:
            //In the case of Includes Only we are basically comparing arrays for equality without minding order.
            //We are using slice in order for sort to not change original array.
            return (
              filterValues
                .map((v) => v.id)
                .slice()
                .sort()
                .toString() === value.slice().sort().toString()
            );
          case CONTAINS_ANY_FILTER_VALUE:
            return filterValues.some((filterValue) =>
              value.includes(filterValue.id)
            );
          case DOES_NOT_CONTAIN_FILTER_VALUE:
            return filterValues.every(
              (filterValue) => !value.includes(filterValue.id)
            );
          case GROUPS_CONTAINS_ALL_FILTER_VALUE:
            return filterValues.every(
              (filterValue) =>
                value.includes(filterValue.id) ||
                filterValue.descendants_ids.some((id) => value.includes(id))
            );
          case GROUPS_CONTAINS_ANY_FILTER_VALUE:
            return filterValues.some(
              (filterValue) =>
                value.includes(filterValue.id) ||
                filterValue.descendants_ids.some((id) => value.includes(id))
            );
          case GROUPS_DOES_NOT_CONTAIN_FILTER_VALUE:
            return filterValues.every(
              (filterValue) =>
                !value.includes(filterValue.id) &&
                !filterValue.descendants_ids.some((id) => value.includes(id))
            );
        }
      } else {
        switch (filterType) {
          case AND_FILTER_TYPE:
            return filterValues.every((filterValue) =>
              value.includes(filterValue.id)
            );
          case OR_FILTER_TYPE:
            return filterValues.some((filterValue) =>
              value.includes(filterValue.id)
            );
        }
      }
    } else if (typeof value === 'string') {
      //one value string - status filter in jobs table
      switch (filterType) {
        case AND_FILTER_TYPE:
          return filterValues.every(
            (filterValue) => value === filterValue.value
          );
        case OR_FILTER_TYPE:
          return filterValues.some(
            (filterValue) => value === filterValue.value
          );
      }
    } else {
      //one value numbers (ids - mapped by, revenue category, etc)
      switch (filterType) {
        case AND_FILTER_TYPE:
          return filterValues.every((filterValue) => filterValue.id === value);
        case OR_FILTER_TYPE:
          if (
            props.colDef.filterParams.operators &&
            filterOperator &&
            filterOperator.value === 'notContains'
          ) {
            return filterValues.every(
              (filterValue) => filterValue.id !== value
            );
          } else
            return filterValues.some((filterValue) => filterValue.id === value);
      }
    }
  };

  const filterPassInverted = (value, filterValues) => {
    //this is only used in job mappings table.
    //2nd condition is for the case we filter by the value to which we previously mapped some code, since after editing value becomes string
    if (Array.isArray(value)) {
      return filterValues.every(
        (filterValue) => !value.includes(filterValue.id)
      );
    } else if (typeof value === 'string') {
      let namesArray = value.split(', ');
      return filterValues.every(
        (filterValue) => !namesArray.includes(filterValue.value)
      );
    } else return filterValues.every((filterValue) => filterValue.id !== value);
  };

  const resetFilter = () => {
    if (filterValues) {
      setFilterValues([]);
    }
    setFilterOperator(defaultOperator);
  };

  const selectProps = {
    options: props.colDef.filterParams.values,
    value: filterValues,
    onValueChange: setFilterValues,
  };

  return (
    <div
      id="table-set-filter"
      onKeyDown={({ key }) => applyFiltersOnKeyPress(key, props)}
    >
      <ResetFilterButton onReset={resetFilter} />
      {props.colDef.filterParams.async ? (
        <CustomAsyncMultiSelect {...selectProps} />
      ) : (
        <>
          {defaultOperator && (
            <Select
              options={props.colDef.filterParams.operators}
              value={filterOperator}
              onChange={(option) => setFilterOperator(option)}
              className="w-full p-2.5"
              menuPosition="fixed"
            />
          )}
          <CustomMultiSelect {...selectProps} />
        </>
      )}
    </div>
  );
});
