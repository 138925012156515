import { memo, useEffect, useState } from 'react';

const ActiveFilters = memo(function ActiveFilters({ status }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!status.length) {
      setIsOpen(false);
    }
  }, [status]);

  return (
    <div data-testid="applied-filters" className="order-first pl-1.5">
      <div className="h-11 flex items-center">
        <span className="ag-group-title-bar-icon ag-filter-toolpanel-group-title-bar-icon">
          <span
            data-testid="dropdown-arrow"
            className={`ag-icon ${
              isOpen ? 'ag-icon-tree-open' : 'ag-icon-tree-closed'
            }`}
            onClick={() => setIsOpen(!isOpen)}
          />
        </span>
        <span className="font-medium">Applied Filters</span>
      </div>
      {status.length > 0 && isOpen && (
        <div className="pl-[15px] pr-2.5">
          <ul>
            {status.map((filter, i) => (
              <li key={i} className="mb-1">
                {`\u2022 ${filter}`}
              </li>
            ))}
          </ul>
        </div>
      )}
      {!status.length && isOpen && (
        <div className="mb-1 pl-[15px] pr-2.5">No active filters</div>
      )}
    </div>
  );
});

export default ActiveFilters;
