import { createAsyncThunk } from '@reduxjs/toolkit';
import { getJobDetails } from '../../services/jobServices';
import { parseError } from '../../helpers/errorHelper';

export const openViewJobDetails = createAsyncThunk(
  'modal/openViewJobDetails',
  async (jobId, { rejectWithValue }) => {
    try {
      const { data } = await getJobDetails(jobId);
      return data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);
