import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { ResetFilterButton } from './ResetFilterButton';
import { applyFiltersOnKeyPress } from '../../../helpers/tableFiltersHelper';

export default forwardRef((props, ref) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [filterValues, setFilterValues] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    props.filterModifiedCallback();
    setIsMenuOpen(false);
  }, [filterValues]);

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        const { api, colDef, column, columnApi, context } = props;
        const { node } = params;

        const value = props.valueGetter({
          api,
          colDef,
          column,
          columnApi,
          context,
          data: node.data,
          getValue: (field) => node.data[field],
          node,
        });

        return filterPass(value, filterValues);
      },

      isFilterActive() {
        return filterValues.length;
      },

      getModel() {
        if (!this.isFilterActive()) {
          return null;
        }

        return {
          values: filterValues.map((fV) => fV.value),
          type: 'creatable',
          label: props.colDef.headerName,
        };
      },

      setModel(model) {
        if (model === null || model === undefined) {
          resetFilter();
        } else {
          setFilterValues(model.values);
        }
      },
    };
  });

  const filterPass = (value, filterValue) => {
    return filterValue.includes(value);
  };

  const resetFilter = () => {
    if (filterValues) {
      setFilterValues([]);
    }
  };

  const createUniqueValuesFromString = (input) => {
    const existingValues = filterValues.map((option) => option.value);
    return input
      .split(/[\s,]+/) // Split by space or comma
      .filter((value) => value && !existingValues.includes(value)) // Remove empty strings and duplicates
      .map((value) => ({ label: value, value }));
  };

  const handleInputChange = (newValue, { action }) => {
    if (action === 'input-change') {
      if (newValue.includes(' ') || newValue.includes(',')) {
        const newValues = createUniqueValuesFromString(newValue);
        setFilterValues((prev) => [...prev, ...newValues]);
        setInputValue('');
      } else {
        setInputValue(newValue);
      }
    }
  };

  const handleChange = (newValues) => {
    setFilterValues(newValues || []);
    setInputValue('');
  };

  return (
    <div
      id="table-radio-filter"
      onKeyDown={({ key }) => applyFiltersOnKeyPress(key, props)}
    >
      <ResetFilterButton onReset={resetFilter} />
      <div className="w-full p-2.5">
        <CreatableSelect
          isMulti
          value={filterValues}
          inputValue={inputValue}
          onChange={handleChange}
          onInputChange={handleInputChange}
          isClearable={false}
          noOptionsMessage={() => null}
          onMenuOpen={() => setIsMenuOpen(true)}
          onMenuClose={() => setIsMenuOpen(false)}
          onKeyDown={(e) => isMenuOpen && e.stopPropagation()}
        />
      </div>
    </div>
  );
});
