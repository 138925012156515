import { memo } from 'react';

const PageSize = memo(function PageSize({
  currentPageSize,
  setPageSize,
  largePageSize = false,
}) {
  return (
    <span className="row-per-page-container">
      <select
        onChange={(e) => setPageSize(e.target.value)}
        id="page-size"
        style={{ margin: '0px', paddingLeft: '0px' }}
        value={currentPageSize} // Bind the value to currentPageSize
      >
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="200">200</option>
        {largePageSize && <option value="500">500</option>}
        {largePageSize && <option value="1000">1000</option>}
      </select>
    </span>
  );
});

export default PageSize;
