import moment from 'moment/moment';
import {
  revenue_category_id,
  review_status,
  verified,
  PRICE_COLUMNS,
  code_tags_ids,
  CSV_EXPORT_COLUMNS,
  mapped_by,
  non_zero_usage,
  non_zero_price,
  quantity_mode_price,
  pms_code_vetsuccess_id,
} from '../constants/constants';
import { getJobs, getJobsHistory } from '../services/jobServices';
import {
  formatCTValues,
  getValueById,
  parseIdFromStringWithId,
  removeBracketsAndNumbers,
} from './tableHelper';
import { isAdmin } from './userHelper';
import {
  canMarkJobAsInvoiced,
  canMarkJobCompleted,
  canMarkJobMappingComplete,
  canUnhideJobFromMapper,
} from './jobsHelper';
import {
  activeJobStatuses,
  CLINIC_JOB_TYPE,
  COMPLETE_JOB_ACTION_LABEL,
  INVOICE_JOB_ACTION_LABEL,
  JOB_STATUS_AVAILABLE,
  JOB_STATUS_COMPLETED,
  JOB_STATUS_COMPLETION_PENDING,
  JOB_STATUS_CREATED,
  JOB_STATUS_FAILED,
  JOB_STATUS_IN_PROGRESS,
  JOB_STATUS_MAPPING_COMPLETE,
  PRIORITY_ORDER,
  RATE_TYPE_PER_HOUR,
  COMPLETE_MAPPING_ACTION_LABEL,
  TYPE_ORDER,
  UNHIDE_JOB_ACTION_LABEL,
} from '../constants/jobConstants';
import { getMappableElements } from './selectHelper';

const jobsSort = (a, b, isAdmin, userId) => {
  const statusOrder = isAdmin
    ? {
        [JOB_STATUS_CREATED]: 0,
        [JOB_STATUS_IN_PROGRESS]: 1,
        [JOB_STATUS_AVAILABLE]: 2,
        [JOB_STATUS_MAPPING_COMPLETE]: 3,
        [JOB_STATUS_COMPLETION_PENDING]: 4,
        [JOB_STATUS_COMPLETED]: 5,
        [JOB_STATUS_FAILED]: 6,
      }
    : {
        [JOB_STATUS_IN_PROGRESS]: 0,
        [JOB_STATUS_AVAILABLE]: 1,
        [JOB_STATUS_MAPPING_COMPLETE]: 2,
        [JOB_STATUS_COMPLETION_PENDING]: 3,
      };

  if (
    a.status === JOB_STATUS_IN_PROGRESS &&
    b.status === JOB_STATUS_IN_PROGRESS
  ) {
    if (a.claimed_by !== userId && b.claimed_by === userId) {
      return 1;
    }
    if (a.claimed_by === userId && b.claimed_by !== userId) {
      return -1;
    }
  }

  if (
    (a.status === JOB_STATUS_AVAILABLE && b.status === JOB_STATUS_AVAILABLE) ||
    (a.status === JOB_STATUS_IN_PROGRESS &&
      b.status === JOB_STATUS_IN_PROGRESS) ||
    (a.status === JOB_STATUS_MAPPING_COMPLETE &&
      b.status === JOB_STATUS_MAPPING_COMPLETE)
  ) {
    if (PRIORITY_ORDER[a.priority] < PRIORITY_ORDER[b.priority]) {
      return -1;
    }
    if (PRIORITY_ORDER[a.priority] > PRIORITY_ORDER[b.priority]) {
      return 1;
    }
    if (TYPE_ORDER[a.job_type] < TYPE_ORDER[b.job_type]) {
      return -1;
    }
    if (TYPE_ORDER[a.job_type] > TYPE_ORDER[b.job_type]) {
      return 1;
    }
    if (a.available_at > b.available_at) {
      return 1;
    }
    if (a.available_at < b.available_at) {
      return -1;
    }
    return 0;
  }

  if (a.status === JOB_STATUS_COMPLETED && b.status === JOB_STATUS_COMPLETED) {
    if (a.completed_at > b.completed_at) {
      return -1;
    }
    if (a.completed_at < b.completed_at) {
      return 1;
    }
    return 0;
  }

  // Place available jobs before in_progress jobs claimed by other users, but after in_progress jobs claimed by current user
  if (
    a.status === JOB_STATUS_IN_PROGRESS &&
    b.status === JOB_STATUS_AVAILABLE
  ) {
    return a.claimed_by === userId ? -1 : 1;
  }
  if (
    a.status === JOB_STATUS_AVAILABLE &&
    b.status === JOB_STATUS_IN_PROGRESS
  ) {
    return b.claimed_by === userId ? 1 : -1;
  }

  if (a.status in statusOrder && b.status in statusOrder) {
    return statusOrder[a.status] - statusOrder[b.status];
  }
  if (a.status in statusOrder) {
    return -1;
  }
  if (b.status in statusOrder) {
    return 1;
  }

  return 0;
};

const jobsHistorySort = (a, b) => {
  if (a.completed_at === null && b.completed_at === null) {
    return b.created_at.localeCompare(a.created_at);
  }
  if (a.completed_at === null) {
    return 1;
  }
  if (b.completed_at === null) {
    return -1;
  }
  return b.completed_at.localeCompare(a.completed_at);
};

export const getMappingJobs = {
  '/jobs': (
    userInfo,
    practices,
    setRowData,
    setErrorAlert,
    setAvailablePractices,
    setBackendResponse,
    users = null
  ) => {
    getJobs()
      .then((res) => {
        res.sort((a, b) => jobsSort(a, b, isAdmin(userInfo), userInfo.id));
        const filteredData = res.filter((r) =>
          activeJobStatuses.includes(r.status)
        );
        setAvailablePractices(filterOutActiveJobsPractices(res, practices));
        if (setBackendResponse) {
          setRowData(
            isAdmin(userInfo)
              ? calculateAdditionalAdminColumns(filteredData, users, practices)
              : filteredData
          );
          setBackendResponse(
            isAdmin(userInfo)
              ? calculateAdditionalAdminColumns(res, users, practices)
              : res
          );
        } else
          setRowData(
            isAdmin(userInfo)
              ? calculateAdditionalAdminColumns(res, users, practices)
              : res
          );
      })
      .catch((error) => setErrorAlert({ error: error }));
  },
  '/history': (setRowData, setErrorAlert) => {
    getJobsHistory()
      .then((res) => {
        res.sort(jobsHistorySort);
        setRowData(res);
      })
      .catch((error) => setErrorAlert({ error: error }));
  },
};

export const calculateAdditionalAdminColumns = (jobs, users, practices) => {
  for (let job of jobs) {
    job.claimed_by_formatted = job.claimed_by
      ? getValueById(users, job.claimed_by)
      : null;
    if (job.job_type === CLINIC_JOB_TYPE) {
      let practice = practices.find((p) => job.practice_id === p.id);
      job.clinic_name = practice ? practice.value : null;
    }
  }
  return jobs;
};

export function filterOutActiveJobsPractices(jobs, practices) {
  let activeJobPractices = jobs
    .filter(
      (job) =>
        activeJobStatuses.includes(job.status) &&
        job.job_type === CLINIC_JOB_TYPE
    )
    .map((job) => {
      return job.practice_id;
    });
  return practices.filter(
    (practice) => !activeJobPractices.includes(practice.id)
  );
}

export const sortVaccineProtocolColumn = (valueA, valueB, isInverted) => {
  if (valueA === (null || '') && valueB === (null || '')) {
    return 0;
  }
  if (valueA === (null || '')) {
    return isInverted ? -1 : 1;
  }
  if (valueB === (null || '')) {
    return isInverted ? 1 : -1;
  }
  if (typeof valueA === 'number' && typeof valueB === 'number') {
    return valueA - valueB;
  } else {
    return valueA.toString().localeCompare(valueB.toString());
  }
};

export const getJobItems = (
  userInfo,
  jobDetails,
  downloadCSVFile,
  markJobMappingComplete,
  markJobCompleted,
  markJobAsInvoiced,
  unhideJob,
  viewJobDetails
) => {
  let items = [];
  canUnhideJobFromMapper(jobDetails, userInfo) &&
    items.push({
      label: UNHIDE_JOB_ACTION_LABEL,
      action: unhideJob,
    });
  isAdmin(userInfo) &&
    items.push({
      label: 'Download CSV',
      action: downloadCSVFile,
    });
  items.push({
    label: 'View Job Details',
    action: viewJobDetails,
  });
  canMarkJobMappingComplete(jobDetails, userInfo) &&
    items.push({
      label: COMPLETE_MAPPING_ACTION_LABEL,
      action: markJobMappingComplete,
    });
  canMarkJobCompleted(jobDetails, userInfo) &&
    items.push({
      label: COMPLETE_JOB_ACTION_LABEL,
      action: markJobCompleted,
    });
  canMarkJobAsInvoiced(jobDetails, userInfo) &&
    items.push({
      label: INVOICE_JOB_ACTION_LABEL,
      action: markJobAsInvoiced,
    });
  return items;
};

export const downloadJobsCSVFile = (
  gridApi,
  allRevenueCategories,
  availableCodeTags,
  users
) => {
  gridApi.exportDataAsCsv({
    columnKeys: CSV_EXPORT_COLUMNS,
    skipColumnGroupHeaders: true,
    fileName: `mapping-data-${moment(Date.now()).format(
      'YYYY-MM-DD_hh.mm.ss'
    )}.csv`,
    processHeaderCallback: (params) => {
      if (params.column.colId === pms_code_vetsuccess_id.field) {
        return pms_code_vetsuccess_id.field;
      }
      return params.column.colDef.headerName;
    },
    processCellCallback: (params) => {
      if (params.column.colId === revenue_category_id.field) {
        let id = parseIdFromStringWithId(params.value);
        return getValueById(allRevenueCategories, id);
      }
      if (params.column.colId === review_status.field) {
        return review_status.values_by_key[params.value];
      }
      if (params.column.colId === verified.field) {
        return params.value ? 'Yes' : 'No';
      }
      if (PRICE_COLUMNS.includes(params.column.colId)) {
        return `$${params.value}`;
      }
      if (params.column.colId === code_tags_ids.field) {
        if (params.value) {
          if (Array.isArray(params.value)) {
            return formatCTValues(
              getMappableElements(availableCodeTags),
              params.value,
              (ct) => ct.name
            );
          } else return removeBracketsAndNumbers(params.value);
        } else return null;
      }
      if (params.column.colId === mapped_by.field) {
        return getValueById(users, params.value);
      }
      if (params.column.colId === non_zero_usage.columnName) {
        return params.node.data[non_zero_usage.field][
          non_zero_usage.nested_field
        ]
          ? `${
              params.node.data[non_zero_usage.field][
                non_zero_usage.nested_field
              ]
            }%`
          : null;
      }
      if (params.column.colId === non_zero_price.columnName) {
        return params.node.data[non_zero_price.field][
          non_zero_price.nested_field
        ]
          ? `$${
              params.node.data[non_zero_price.field][
                non_zero_price.nested_field
              ]
            }`
          : null;
      }
      if (params.column.colId === quantity_mode_price.columnName) {
        return params.node.data[quantity_mode_price.field][
          quantity_mode_price.nested_field
        ]
          ? `$${
              params.node.data[quantity_mode_price.field][
                quantity_mode_price.nested_field
              ]
            }`
          : null;
      }
      return params.value;
    },
  });
};

export const calculateMappedCodeCount = (gridApi, jobDetails) => {
  let codeCount = 0;
  gridApi.forEachNode((node) => {
    if (
      node.data.verified &&
      moment(node.data.mapped_at).isAfter(moment(jobDetails.claimed_at))
    ) {
      codeCount += 1;
    }
  });
  return codeCount;
};

export const falsyValue = (value) => {
  return value === null || value === undefined;
};

export const formatHours = (minutes) => {
  if (!minutes) {
    return '00:00';
  } else {
    let hours = Math.floor(minutes / 60);
    let h = hours < 10 ? `0${hours}` : hours;
    let m = minutes % 60 < 10 ? `0${minutes % 60}` : minutes % 60;
    return h + ':' + m;
  }
};

export const hoursEditableForUser = (
  status,
  applicableStatuses,
  invoiced,
  rateType = RATE_TYPE_PER_HOUR
) => {
  return (
    rateType === RATE_TYPE_PER_HOUR &&
    applicableStatuses.includes(status) &&
    !invoiced
  );
};

export const getBatchInvoiceJobsResultMessage = (
  jobIds,
  noJobMetInvoiceCriteria
) => {
  if (noJobMetInvoiceCriteria) {
    return 'None of the selected jobs met the invoicing criteria and have been skipped. Please review these jobs, adjust their status and try again.';
  } else {
    if (jobIds.length > 1) {
      return (
        <>
          Jobs <b>{jobIds.join(', ')}</b> did not meet the invoicing criteria
          and have been skipped. Please review these jobs, adjust their status
          and try again.
        </>
      );
    } else {
      return (
        <>
          Job <b>{jobIds[0]}</b> did not meet the invoicing criteria and has
          been skipped. Please review it, adjust status and try again.
        </>
      );
    }
  }
};
