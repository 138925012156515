import { useEffect, useRef } from 'react';
import { Dialog } from '@headlessui/react';
import JobDetails from './JobDetails';
import { useDispatch, useSelector } from 'react-redux';
import JobDetailsEditModal from './JobDetailsEditModal';
import { EDIT_JOB_DETAILS_MODAL } from '../../../constants/modals';
import EditJobForm from './EditJobForm';
import { modalClosed } from '../../../features/modal/modalSlice';

export default function JobDetailsModal({ isOpen, onClose }) {
  const jobDetailsRef = useRef();
  const { visibleModal, jobDetailsModalData } = useSelector(
    (state) => state.modal
  );
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(modalClosed());
    };
  }, []);
  if (jobDetailsModalData) {
    return (
      <>
        <Dialog
          open={isOpen}
          onClose={() => onClose(false)}
          initialFocus={jobDetailsRef}
        >
          <div className="fixed inset-0 bg-black/30 " aria-hidden="true" />
          <div className="fixed inset-0 overflow-y-auto">
            <div
              ref={jobDetailsRef}
              className="flex items-center justify-center p-4 text-center"
            >
              <Dialog.Panel className="w-[700px] rounded-sm bg-white px-10 py-6 text-left align-middle shadow-xl flex-col ">
                <JobDetails
                  mappingJobDetails={jobDetailsModalData}
                  onClose={onClose}
                ></JobDetails>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
        {visibleModal === EDIT_JOB_DETAILS_MODAL && (
          <JobDetailsEditModal
            openState={visibleModal === EDIT_JOB_DETAILS_MODAL}
            title={`Edit job details (#${jobDetailsModalData.id})`}
            form={<EditJobForm />}
          />
        )}
      </>
    );
  }
}
