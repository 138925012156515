import { useContext, useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { injectStore } from './services/apiSecure';
import Login from './components/Login';
import JobsHistory from './components/job/JobsHistory';
import Jobs from './components/job/Jobs';
import MyProfile from './components/users/MyProfile';
import ResetPassword from './components/ResetPassword';
import RouteRedirect from './components/RouteRedirect';
import AdminMapping from './components/mapping/AdminMapping';
import JobMapping from './components/mapping/JobMapping';
import ProtectedRoute from './components/ProtectedRoute';
import AllUsers from './components/users/AllUsers';
import Config from './components/configuration/Config';
import ColoredButton from './components/common/ColoredButton';
import {
  ADMIN_ROLES,
  ALL_ROLES,
  JOBS_ROLES,
  SUPER_ADMIN_ROLE,
  VSO_TEAM_ROLE,
} from './constants/roles';
import { logout, refreshTokens, resetState } from './features/user/userActions';
import { tokenExpired } from './utils/helper';
import { logger } from './services/logger';
import { ErrorContext } from './ErrorContext';

function App() {
  const onErrorReset = (resetError) => {
    resetError();
    resetErrorAlert();
    dispatch(resetState());
  };
  injectStore(useStore());
  const dispatch = useDispatch();
  const { resetErrorAlert } = useContext(ErrorContext);
  const { tokenExpiry, refreshTokenExpiry, refreshTokenInProgress } =
    useSelector((state) => state.user);
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        if (
          tokenExpired(tokenExpiry) &&
          !tokenExpired(refreshTokenExpiry) &&
          !refreshTokenInProgress
        ) {
          dispatch(refreshTokens());
        }
        if (tokenExpired(tokenExpiry) && tokenExpired(refreshTokenExpiry)) {
          dispatch(logout());
        }
      }
    };
    // Add listener-a
    document.addEventListener('visibilitychange', handleVisibilityChange);
    // Cleanup listener
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [tokenExpiry, refreshTokenExpiry, refreshTokenInProgress]);
  return (
    <Sentry.ErrorBoundary
      fallback={({ resetError }) => (
        <>
          <div className="flex flex-col items-center h-full p-20">
            <div className="text-xl font-semibold mb-8">
              Sorry, something went wrong
            </div>
            <ColoredButton
              onClick={() => onErrorReset(resetError)}
              text="Click to reset"
            />
          </div>
        </>
      )}
    >
      <BrowserRouter>
        <Routes>
          <Route
            path="*"
            element={<p>Sorry, we couldn&apos;t find this page</p>}
          />
          <Route element={<ProtectedRoute roles={ALL_ROLES} />}>
            <Route path="/myprofile" element={<MyProfile />} />
          </Route>
          <Route
            element={<ProtectedRoute roles={[...ADMIN_ROLES, VSO_TEAM_ROLE]} />}
          >
            <Route path="/mappings" element={<AdminMapping />} />
          </Route>
          <Route element={<ProtectedRoute roles={JOBS_ROLES} />}>
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/history" element={<JobsHistory />} />
            <Route path="/jobmapping/:jobId" element={<JobMapping />} />
          </Route>
          <Route element={<ProtectedRoute roles={[SUPER_ADMIN_ROLE]} />}>
            <Route path="/allusers" element={<AllUsers />} />
            <Route path="/config" element={<Config />} />
          </Route>
          <Route element={<RouteRedirect />}>
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Login />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  );
}
export default App;
