import {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { revenue_category_id } from '../constants/constants';
import Select from 'react-select';
import { htmlDecode, parseNameFromStringWithId } from '../helpers/tableHelper';
import { enterPressed } from '../utils/helper';
import { selectFilter } from '../helpers/selectHelper';

const customClassName = 'ms-single-select';
const focusedElementSelector = `.${customClassName}__option--is-focused`;
const selectedElementSelector = `.${customClassName}__option--is-selected`;

/**
 *
 * props:
 * options: List of values that will be used to populate react-select options, placeholder and initial value
 * isSelectable<optional>: Predicate that will be used (if present) to filter out only values that can be selected in dropdown (i.e. mappable RC/CT) - this options will be used in react-select and in formatValue fn
 */
const isRevenueCategoryColumn = (column) =>
  column === revenue_category_id.field;

const SingleSelectCellEditor = forwardRef((props, ref) => {
  const selectableOptions = props.isSelectable
    ? props.options.filter((option) => props.isSelectable(option))
    : props.options;
  let parsedValue =
    props.value && isRevenueCategoryColumn(props.column.colId)
      ? parseNameFromStringWithId(props.value)
      : props.value;
  const [value, setValue] = useState(() => {
    if (props.value) {
      let value = props.options.find(
        (option) => option.value === parsedValue || option.id === parsedValue
      );
      return value;
    } else return null;
  });
  //handle first letter lost on typing and enter key - AG GRID issue (not resolved) - only for RC (serchable)
  //TODO: we should create two separate components (or introduce new prop) for serachable/non searchable selects
  const [inputValue, setInputValue] = useState(
    isRevenueCategoryColumn(props.column.colId) &&
      props.eventKey &&
      props.eventKey.length === 1
      ? props.eventKey
      : ''
  );
  const [outsideScroll, setOutsideScroll] = useState(false);
  const [clickOnMenu, setClickedOnMenu] = useState(false);
  const [clickOnSelf, setClickedSelf] = useState(false);
  const [enterSelection, setEnterSelection] = useState(false);
  const [width] = useState(props.column.actualWidth);
  const refInput = useRef(null);
  const input = useRef(null);

  // we have to listen on the scrolling events outside of the react-select
  // and react to it - when we start scrolling outside of menu list, we have to finish editing
  useEffect(() => {
    if (outsideScroll) {
      props.stopEditing();
    }
  }, [outsideScroll]);

  useEffect(() => {
    document.addEventListener('scroll', handleScrollOutside, true);
    return () => {
      document.removeEventListener('scroll', handleScrollOutside, true);
    };
  }, []);

  const handleScrollOutside = (event) => {
    if (input.current && !input.current.contains(event.target)) {
      setOutsideScroll(true);
    }
  };

  const CustomStyle = {
    control: (styles) => ({
      ...styles,
      width: width,
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      display: 'none',
    }),
  };

  const onInputChanged = (input) => {
    setInputValue(input);
    scrollOptionIntoView(focusedElementSelector);
  };

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        // handling finish editing on enter
        // accepting current highlighted option if present, if only selected is present use that one
        let focusedOption = document.querySelector(focusedElementSelector);
        let selectedOption = document.querySelector(selectedElementSelector);

        if (focusedOption) {
          return formatValue(htmlDecode(focusedOption.innerHTML));
        } else if (selectedOption) {
          return formatValue(htmlDecode(selectedOption.innerHTML));
        } else value ? value.value : null;
      },

      isCancelAfterEnd() {
        // if options list is empty - cancel edit
        // if scroll happened before confirming(pressing enter)- cancel editing.
        // if option is not confirmed with enter or clicking on option in menu - cancel editing (i.e. - click outside of the menu)
        // Either of those is sufficient for selection to be accepted.
        return (
          (inputValue && optionsEmpty()) ||
          outsideScroll ||
          clickOnSelf ||
          !(clickOnMenu || enterSelection)
        );
      },

      isPopup() {
        return true;
      },
    };
  });

  const formatValue = (value) => {
    return selectableOptions.find((v) => v.value === value).id;
  };

  const onMenuOpen = () => {
    scrollOptionIntoView(selectedElementSelector);
  };

  const optionsEmpty = () => {
    return !selectableOptions.some((candidate) =>
      selectFilter(candidate, inputValue)
    );
  };

  const scrollOptionIntoView = (optionClass) => {
    setTimeout(() => {
      const option = document.querySelector(optionClass);
      option && option.scrollIntoView({ behavior: 'instant', block: 'start' });
    }, 15);
  };

  return (
    <div
      translate="no"
      ref={input}
      onClickCapture={(e) => {
        if (e.target.className.includes(`${customClassName}__single-value`)) {
          setClickedSelf(true);
        } else setClickedOnMenu(true);
      }}
      onKeyDownCapture={(e) => {
        setEnterSelection(enterPressed(e));
      }}
    >
      {isRevenueCategoryColumn(props.column.colId) ? (
        <Select
          ref={refInput}
          defaultValue={value}
          options={selectableOptions}
          className={customClassName}
          classNamePrefix={customClassName}
          filterOption={selectFilter}
          onInputChange={onInputChanged}
          inputValue={inputValue}
          menuPlacement="auto"
          menuPosition="fixed"
          styles={CustomStyle}
          onChange={(option) => {
            setValue(option);
            props.stopEditing();
          }}
          autoFocus
          openMenuOnFocus
          onMenuOpen={onMenuOpen}
        />
      ) : (
        <Select
          ref={refInput}
          defaultValue={value}
          isSearchable={false}
          options={selectableOptions}
          className={customClassName}
          classNamePrefix={customClassName}
          menuPlacement="auto"
          styles={CustomStyle}
          onChange={(option) => {
            setValue(option);
            props.stopEditing();
          }}
          autoFocus
          openMenuOnFocus
        />
      )}
    </div>
  );
});

export default SingleSelectCellEditor;
