import { memo } from 'react';
import { Dialog } from '@headlessui/react';
import OutlineButton from '../../common/OutlineButton';
import ColoredButton from '../../common/ColoredButton';
import warningIcon from '../../../assets/icons/warning.svg';

const CodeLimitModal = memo(function CodeLimitModal({
  isOpen,
  onClose,
  onButtonClick,
}) {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Dialog.Panel className="max-w-md transform overflow-hidden rounded-2xl bg-white px-14 py-10 text-left align-middle shadow-xl transition-all">
            <Dialog.Title
              as="h3"
              className="text-center text-lg font-medium leading-6 font-bold text"
            >
              <img
                className="mx-auto mb-5"
                src={warningIcon}
                alt="warning-icon"
              />
              Code limit exceeded
            </Dialog.Title>
            <div className="my-4 text-center">
              <p className="inline text-sm text-gray-400 whitespace-pre-wrap">
                The total number of codes, including those without transactions
                is over 20,000. Would you like to create the job without the
                codes that don’t have transactions?
              </p>
            </div>
            <div className="text-center mt-6">
              <OutlineButton
                text="No, cancel"
                heightClass="h-10"
                onClick={onClose}
              />
              <ColoredButton
                text="Yes, exclude codes"
                widthClass="w-[180px]"
                heightClass="h-10"
                onClick={onButtonClick}
              />
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
});

export default CodeLimitModal;
