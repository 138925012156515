export const errorCodesConstants = {
  0: {
    default: {
      description:
        'We were not able to connect to the server. Check your internet connectivity and try again.',
      title: 'Connection Lost',
    },
  },
  400: {
    not_active_practice: ({ errorParams }) => {
      return {
        description: `Cannot find active practice ${errorParams.practice_id}.`,
        title: 'Not Found',
      };
    },
    s3_upload_failed: {
      description: 'Job has been created with status failed.',
      title: 'Failed',
    },
    job_deletion_in_progress: ({ errorParams }) => {
      return {
        description: `Mapping job with id ${errorParams.mapping_job_id} cannot be deleted while data import is in progress.`,
        title: 'Failed',
      };
    },
    practice_not_automapped: {
      description: 'Practice is not automapped.',
      title: 'Unavailable Practice',
    },
    request_timeout: {
      description:
        'Looks like the request took too long. Try narrowing down your search and try again.',
      title: 'Request Timeout',
    },
  },
  403: {
    forbidden: ({ errorParams }) => {
      if (errorParams.resource_type === 'MappingJob') {
        switch (errorParams.action) {
          case 'claim_job':
            return {
              description: 'This job has already been claimed.',
              title: 'Forbidden Resource',
            };
          case 'complete':
            return {
              description: 'This job has already been onboarded.',
              title: 'Forbidden Resource',
            };
          case 'complete_mapping':
            return {
              description: 'This job has already been marked as completed.',
              title: 'Forbidden Resource',
            };
          default:
            return {
              description: 'You are not authorized for viewing this job',
              title: 'Forbidden Resource',
            };
        }
      } else {
        return {
          description:
            'You are not authorized to view and/or update this resource.',
          title: 'Forbidden Resource',
        };
      }
    },
  },
  404: {
    not_found: ({ errorParams }) => {
      if (errorParams.resource_type === 'MappingJob') {
        return {
          description: 'Job not found.',
          title: 'Not Found',
        };
      } else {
        return {
          description: 'Please try again or report the issue.',
          title: 'Something went wrong',
        };
      }
    },
  },
  422: {
    admin_description_too_long: {
      description: 'Admin description can have up to 1000 characters.',
      title: 'Invalid Value',
    },
    admin_notes_too_long: {
      description: 'Admin notes can have up to 1000 characters.',
      title: 'Invalid Value',
    },
    claimed_by_not_active_user: {
      description: 'Cannot assign job to inactive user.',
      title: 'User not active',
    },
    claimed_by_not_mapper_user: {
      description: 'User must be present and must be mapper.',
      title: 'User not mapper',
    },
    claimed_by_vso_user: {
      description: 'Cannot assign job to VSO user.',
      title: 'User not active',
    },
    invalid_job_code_count: {
      description: 'Invalid code count.',
      title: 'Invalid code count',
    },
    practice_duplicated: {
      description: 'Practice is already used for another active mapping job.',
      title: 'Job Exists',
    },
    invalid_file_format: {
      description:
        'Invalid file format, make sure the file is correctly formatted.',
      title: 'Invalid File',
    },
    practice_type_invalid_value: {
      description: 'Practice type is not valid.',
      title: 'Invalid Value',
    },
    default: {
      description:
        'We were not able to process the request. Try again or report the issue.',
      title: 'Unprocessable Content',
    },
  },
};

export const clientErrors = {
  completedJobCsv: {
    // eslint-disable-next-line quotes
    description: "Old completed jobs can't be downloaded.",
    title: 'Failed',
  },
  ctaError: {
    title: 'Something went wrong',
    description: 'Please refresh the table and try again.',
  },
};

export const defaultError = {
  description: 'Please try again or report the issue.',
  title: 'Something went wrong',
};

export const invalidValueError = (value) => {
  return {
    description: `Failed to find ${value} in the list. Please try setting the valid value or report the issue.`,
    title: 'Invalid value',
  };
};
