import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import OutlineButton from '../../common/OutlineButton';
import ColoredButton from '../../common/ColoredButton';
import { useSelector } from 'react-redux';
import { getMappableElements } from '../../../helpers/selectHelper';

export default function AddCodeTagsForm({
  onCancel,
  onSubmitHandler,
  availableCodeTags,
}) {
  const { buttonsDisabled, serverError } = useSelector((state) => state.modal);

  const onSubmit = (data) => {
    onSubmitHandler({
      code_tag_ids: data.addCodeTagsSelect.map((code) => code.id),
    });
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div translate="no" className={'text-13 my-6 px-1 w-[260px]'}>
        <Controller
          name="addCodeTagsSelect"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              isMulti
              isDisabled={buttonsDisabled}
              options={getMappableElements(availableCodeTags)}
            />
          )}
        />
        {errors.addCodeTagsSelect?.type === 'required' && (
          <p className="absolute text-red-500 text-center w-60 my-1 text-xs">
            Code tags is required
          </p>
        )}
        <p className="text-red-500 text-center h-8 w-60 my-1 text-xs">
          {serverError}
        </p>
      </div>
      <OutlineButton
        text="Cancel"
        heightClass="h-10"
        onClick={onCancel}
        disabled={buttonsDisabled}
      />
      <ColoredButton
        text="OK"
        heightClass="h-10"
        submit={true}
        disabled={buttonsDisabled}
      />
    </form>
  );
}
