import {
  ADMIN_ROLES,
  MAPPER_ROLE,
  VSO_TEAM_ROLE,
  SUPER_ADMIN_ROLE,
} from '../constants/roles';

export function isAdmin(userInfo) {
  return ADMIN_ROLES.includes(userInfo.role);
}
export function isMapper(userInfo) {
  return MAPPER_ROLE === userInfo.role;
}
export function isSuperAdmin(userInfo) {
  return userInfo.role === SUPER_ADMIN_ROLE;
}

export function isVSOTeamUser(userInfo) {
  return VSO_TEAM_ROLE === userInfo.role;
}

export function getUserInitials(userInfo) {
  return userInfo ? `${userInfo.first_name[0]}${userInfo.last_name[0]}` : '';
}

export function getUsersDetails(items) {
  return items.map((item) => {
    delete item['password_digest'];
    delete item['created_at'];
    delete item['updated_at'];
    return item;
  });
}
