import { useState } from 'react';
import user from '../../assets/icons/user.svg';
import edit from '../../assets/icons/edit.svg';
import { useSelector } from 'react-redux';
import EditMyProfileForm from './forms/EditMyProfileForm';
import ResetPasswordForm from './forms/ResetPasswordForm';
import { ROLE_NAMES } from '../../constants/roles';
import FormModal from '../common/FormModal';

export default function MyProfile() {
  const { userInfo } = useSelector((state) => state.user);
  const [resetPasswordOpen, setResetModalOpen] = useState(false);
  const [editUserOpen, setEditUserModalOpen] = useState(false);

  return (
    <>
      <div className="bg-background-gray h-full flex text-dark-text">
        <div className="m-4 rounded-lg bg-white shadow-basic flex-1">
          <div className="py-8 px-14">
            <div className="flex max-w-xs mb-4 items-center">
              <img src={user} width="45" height="45" alt="user-icon" />
              <div className="text-lg flex-1 font-medium ml-5">
                {userInfo.firstName + ' ' + userInfo.lastName}
              </div>
              <img
                className="mt-2 cursor-pointer"
                src={edit}
                width="30"
                height="30"
                alt="edit-icon"
                onClick={() => setEditUserModalOpen(true)}
              />
            </div>
            <div className="flex flex-col py-4 px-6 ml-5 border-l-4 border-grayish-blue">
              <div className="inline-flex mb-5">
                <div className="mr-2">Display Name: </div>
                <div>{userInfo.displayName}</div>
              </div>
              <div className="inline-flex mb-5">
                <div className="mr-2">Email: </div>
                <div>{userInfo.email}</div>
              </div>
              <div className="inline-flex mb-5">
                <div className="mr-2">Role: </div>
                <div>{ROLE_NAMES[userInfo.role]}</div>
              </div>
              <div
                onClick={() => setResetModalOpen(true)}
                className="cursor-pointer hover:underline text-vs-green hover:text-vs-green-hover"
              >
                Reset password
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormModal
        openState={resetPasswordOpen}
        onClose={() => setResetModalOpen(false)}
        title="New password"
        form={<ResetPasswordForm onCancel={() => setResetModalOpen(false)} />}
      />
      <FormModal
        openState={editUserOpen}
        onClose={() => setEditUserModalOpen(false)}
        title="Edit User Profile"
        form={
          <EditMyProfileForm onCancel={() => setEditUserModalOpen(false)} />
        }
      />
    </>
  );
}
