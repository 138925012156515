import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { addUserTableConfiguration } from '../../../features/user-configuration/userConfigActions';
import ColoredButton from '../../common/ColoredButton';
import OutlineButton from '../../common/OutlineButton';

export default function SaveLayoutForm({
  configType,
  maxLayouts,
  gridApi,
  filterLayout,
  layouts,
  activeConfigName,
  setOverrideLayout,
  onFinish,
  onCancel,
}) {
  const dispatch = useDispatch();
  const [serverError, setServerError] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: { name: activeConfigName },
  });
  const layoutName = watch('name');

  const submitForm = async (data) => {
    const columnState = gridApi.getColumnState().map((state) => ({
      colId: state.colId,
      hide: state.hide,
      width: state.width,
      sort: state.sort,
      sortIndex: state.sortIndex,
    }));
    const userConfig = {
      columnState: columnState,
      filterOrder: filterLayout.current,
      pageSize: gridApi.paginationGetPageSize(),
    };
    if (isLayoutNameUnique(data.name)) {
      try {
        await dispatch(
          addUserTableConfiguration({
            configuration: userConfig,
            name: data.name,
            config_type: configType,
          })
        ).unwrap();
        onFinish();
      } catch (error) {
        setServerError(error.description);
      }
    } else {
      onCancel();
      setOverrideLayout({ name: data.name, config: userConfig });
    }
  };

  const isLayoutNameUnique = (name) => {
    return !layouts.some((config) => config.name === name);
  };

  const validateLimit = (name) => {
    return (
      layouts.length < maxLayouts ||
      !isLayoutNameUnique(name) ||
      `You have reached limit of ${maxLayouts} layouts`
    );
  };

  return (
    <>
      <form className="mt-6" onSubmit={handleSubmit(submitForm)}>
        <div className="relative mb-8">
          <input
            id="layoutName"
            type="text"
            className="formInput peer"
            placeholder="Enter layout name..."
            {...register('name', {
              required: 'Layout Name is required',
              validate: validateLimit,
            })}
          />
          <label htmlFor="layoutName" className="formLabel">
            Layout Name
          </label>
          {errors.name && <p className="formError">{errors.name.message}</p>}
          {!isLayoutNameUnique(layoutName) && (
            <p className="absolute text-xs text-dark-text text-opacity-60">
              Layout with this name already exists.
            </p>
          )}
          <p className="formError text-center w-[288px]">{serverError}</p>
        </div>
        <div className="text-center">
          <OutlineButton
            outlineColor="border-gray"
            textColor="text-gray"
            text="Cancel"
            widthClass="w-[120px]"
            heightClass="h-10"
            onClick={onCancel}
          />
          <ColoredButton
            fillColor="bg-vs-green"
            text="Save"
            widthClass="w-[120px]"
            heightClass="h-10"
            submit={true}
          />
        </div>
      </form>
    </>
  );
}
