import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { usePopper } from 'react-popper';
import { isSuperAdmin } from '../../../helpers/userHelper';
import infoIcon from '../../../assets/icons/info.svg';

export default function ExcludedGroupsTooltip() {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.user);
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  const [displayHiddenGroupsTooltip, setDisplayHiddenGroupsTooltip] =
    useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [9, 0],
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom-end'],
        },
      },
    ],
  });
  return (
    <div
      onMouseLeave={() => setDisplayHiddenGroupsTooltip(false)}
      onMouseEnter={() => setDisplayHiddenGroupsTooltip(true)}
    >
      <img
        className="ml-1.5 py-2"
        src={infoIcon}
        alt="info-icon"
        ref={setReferenceElement}
      />
      {displayHiddenGroupsTooltip && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="popper-status-tooltip"
        >
          <p>When selected, this job will contain</p>
          <p>all codes excluded by default,</p>
          <p>as well as the ones excluded through</p>
          <p>Excluded Practice Groups configuration.</p>
          {isSuperAdmin(userInfo) && (
            <span
              className="text-purple-vs cursor-pointer"
              onClick={() => navigate('/config')}
            >
              Open configuration
            </span>
          )}
          <div id="arrow" className="right-3" data-popper-arrow></div>
        </div>
      )}
    </div>
  );
}
