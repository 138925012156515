import { createSlice } from '@reduxjs/toolkit';
import { logout, resetState } from '../user/userActions';
import {
  ADD_CODE_TAGS_MODAL,
  COMPLETE_JOB_MODAL,
  EDIT_JOB_DETAILS_MODAL,
  INVOICE_JOB_MODAL,
  JOB_DETAILS_MODAL,
  COMPLETE_MAPPING_JOB_MODAL,
  COMPLETE_MAPPING_JOB_PRACTICE_TYPE_WARNING_MODAL,
  REMOVE_CODE_TAGS_MODAL,
  REPLACE_CODE_TAGS_MODAL,
  UNHIDE_JOB_MODAL,
  UPDATE_FREE_DOSES_MODAL,
  UPDATE_PAID_DOSES_MODAL,
  UPDATE_REVENUE_CATEGORY_MODAL,
  UPDATE_REVIEW_STATUS_MODAL,
  UPDATE_VERIFICATION_STATUS_MODAL,
} from '../../constants/modals';
import { openViewJobDetails } from './modalActions';
import {
  jobDetailsEditSaved,
  jobMappedTimeSaved,
  jobNotesSaved,
  jobPracticeTypeSaved,
} from '../job/jobActions';
import {
  COMPLETE_MAPPING_DIALOG_TEXT,
  COMPLETE_MAPPING_REFRESH_WARNING_DIALOG_IMPORTANT_TEXT,
  COMPLETE_MAPPING_REFRESH_WARNING_DIALOG_TEXT,
  COMPLETE_MAPPING_WARNING_DIALOG_IMPORTANT_TEXT,
  COMPLETE_MAPPING_WARNING_DIALOG_TEXT,
  getTimeSpentConfirmationDialogText,
} from '../../constants/jobConstants';

const initialState = {
  visibleModal: null,
  visibleModalImportantText: null,
  visibleModalText: null,
  visibleModalOperationType: null,
  serverError: null,
  buttonsDisabled: false,
  jobDetailsModalData: null,
  editJobDetails: null,
  openDetailsOnClose: null,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState: initialState,
  reducers: {
    modalClosed: (state) => {
      state.visibleModal = null;
      state.serverError = null;
      state.buttonsDisabled = false;
    },
    confirmButtonClicked: (state) => {
      state.buttonsDisabled = true;
    },
    revenueCategoryBatchActionClicked: (state) => {
      state.visibleModal = UPDATE_REVENUE_CATEGORY_MODAL;
      state.serverError = null;
    },
    addCodeTagsBatchActionClicked: (state) => {
      state.visibleModal = ADD_CODE_TAGS_MODAL;
      state.serverError = null;
    },
    replaceCodeTagsBatchActionClicked: (state) => {
      state.visibleModal = REPLACE_CODE_TAGS_MODAL;
      state.serverError = null;
    },
    removeCodeTagsBatchActionClicked: (state) => {
      state.visibleModal = REMOVE_CODE_TAGS_MODAL;
      state.serverError = null;
    },
    updatePaidDosesBatchActionClicked: (state) => {
      state.visibleModal = UPDATE_PAID_DOSES_MODAL;
      state.serverError = null;
    },
    updateFreeDosesBatchActionClicked: (state) => {
      state.visibleModal = UPDATE_FREE_DOSES_MODAL;
      state.serverError = null;
    },
    updateReviewStatusBatchActionClicked: (state) => {
      state.visibleModal = UPDATE_REVIEW_STATUS_MODAL;
      state.serverError = null;
    },
    updateVerificationStatusBatchActionClicked: (state) => {
      state.visibleModal = UPDATE_VERIFICATION_STATUS_MODAL;
      state.serverError = null;
    },
    invoiceJobClicked: (state) => {
      state.visibleModal = INVOICE_JOB_MODAL;
      state.serverError = null;
    },
    unhideJobClicked: (state) => {
      state.visibleModal = UNHIDE_JOB_MODAL;
      state.serverError = null;
    },
    markJobCompleteMappingClicked: (state, { payload }) => {
      if (payload.visibleModal === COMPLETE_MAPPING_JOB_MODAL) {
        const mappedTimeInfo = payload.mappedTime
          ? getTimeSpentConfirmationDialogText(payload.mappedTime)
          : '';
        if (!payload.allCodesVerified) {
          state.visibleModalImportantText =
            COMPLETE_MAPPING_WARNING_DIALOG_IMPORTANT_TEXT;
          state.visibleModalText =
            mappedTimeInfo + COMPLETE_MAPPING_WARNING_DIALOG_TEXT;
          state.visibleModalOperationType = 'warning';
        } else if (payload.unverifiedCodesExists && payload.allCodesVerified) {
          state.visibleModalImportantText =
            COMPLETE_MAPPING_REFRESH_WARNING_DIALOG_IMPORTANT_TEXT;
          state.visibleModalText =
            mappedTimeInfo + COMPLETE_MAPPING_REFRESH_WARNING_DIALOG_TEXT;
          state.visibleModalOperationType = 'warning';
        } else {
          state.visibleModalText =
            mappedTimeInfo + COMPLETE_MAPPING_DIALOG_TEXT;
          state.visibleModalOperationType = 'info';
        }
      } else if (
        payload.visibleModal ===
        COMPLETE_MAPPING_JOB_PRACTICE_TYPE_WARNING_MODAL
      ) {
        state.unverifiedCodesExists = payload.unverifiedCodesExists;
        state.allCodesVerified = payload.allCodesVerified;
      }
      state.visibleModal = payload.visibleModal;
    },
    markJobCompletedClicked: (state, { payload }) => {
      state.visibleModal = payload?.visibleModal || COMPLETE_JOB_MODAL;
    },
    serverErrorInModal: (state, action) => {
      state.serverError = action.payload;
      state.buttonsDisabled = false;
    },
    editJobDetailsClicked: (state) => {
      state.visibleModal = EDIT_JOB_DETAILS_MODAL;
      state.openDetailsOnClose = true;
    },
    editJobDetailsActionClicked: (state, { payload }) => {
      state.visibleModal = EDIT_JOB_DETAILS_MODAL;
      state.jobDetailsModalData = payload;
      state.openDetailsOnClose = false;
    },
    jobDetailsEditCanceled: (state, action) => {
      state.jobDetailsModalData = action.payload;
      if (state.openDetailsOnClose) {
        state.visibleModal = JOB_DETAILS_MODAL;
      } else {
        state.visibleModal = null;
      }
    },
  },
  extraReducers: {
    [logout]: (state) => {
      state.visibleModal = null;
    },
    [jobDetailsEditSaved]: (state, { payload }) => {
      state.jobDetailsModalData = payload;
      if (state.openDetailsOnClose) {
        state.visibleModal = JOB_DETAILS_MODAL;
      } else {
        state.visibleModal = null;
      }
    },
    [jobNotesSaved]: (state, { payload }) => {
      state.jobDetailsModalData = payload;
    },
    [jobMappedTimeSaved]: (state, { payload }) => {
      state.jobDetailsModalData = payload;
    },
    [jobPracticeTypeSaved]: (state, { payload }) => {
      state.jobDetailsModalData = payload;
    },
    [openViewJobDetails.pending]: (state) => {
      state.visibleModal = null;
      state.error = null;
    },
    [openViewJobDetails.fulfilled]: (state, { payload }) => {
      state.visibleModal = JOB_DETAILS_MODAL;
      state.jobDetailsModalData = payload;
    },
    [resetState]: () => initialState,
  },
});

export const {
  serverErrorInModal,
  modalClosed,
  revenueCategoryBatchActionClicked,
  addCodeTagsBatchActionClicked,
  replaceCodeTagsBatchActionClicked,
  removeCodeTagsBatchActionClicked,
  updateReviewStatusBatchActionClicked,
  updatePaidDosesBatchActionClicked,
  updateFreeDosesBatchActionClicked,
  updateVerificationStatusBatchActionClicked,
  jobDetailsEditCanceled,
  markJobCompleteMappingClicked,
  markJobCompletedClicked,
  editJobDetailsClicked,
  editJobDetailsActionClicked,
  confirmButtonClicked,
  invoiceJobClicked,
  unhideJobClicked,
} = modalSlice.actions;

export default modalSlice.reducer;
