export const didUserConfigChange = (event, setIsUserConfigChanged) => {
  switch (event.type) {
    case 'columnResized':
      if (
        event.source === 'uiColumnResized' ||
        event.source === 'autosizeColumns'
      ) {
        setIsUserConfigChanged(true);
      }
      break;
    case 'columnVisible':
    case 'columnMoved':
    case 'sortChanged':
      if (event.source !== 'api') {
        setIsUserConfigChanged(true);
      }
      break;
  }
};
export const addDragEvents = (
  tableRef,
  filterLayout,
  setIsUserConfigChanged
) => {
  if (tableRef?.current) {
    const filterDivs = tableRef.current.querySelectorAll(
      '.ag-filter-toolpanel-group-wrapper'
    );
    filterDivs.forEach((div, index) => {
      const titleBar = div.querySelector('.ag-group-title-bar');
      titleBar.setAttribute('draggable', 'true');
      div.setAttribute('data-index', index);
      titleBar.addEventListener('dragstart', (e) => handleDragStart(e, div));
      titleBar.addEventListener('dragover', handleDragOver);
      titleBar.addEventListener('drop', (e) =>
        handleDrop(e, filterLayout, setIsUserConfigChanged, div)
      );
    });
  }
};

export const removeDragEvents = (tableRef) => {
  if (tableRef?.current) {
    const filterDivs = tableRef.current.querySelectorAll(
      '.ag-filter-toolpanel-group-wrapper'
    );
    filterDivs.forEach((div) => {
      div.removeEventListener('dragstart', handleDragStart);
      div.removeEventListener('dragover', handleDragOver);
      div.removeEventListener('drop', handleDrop);
    });
  }
};

export const resetDragEvents = (
  tableRef,
  filterLayout,
  setIsUserConfigChanged
) => {
  removeDragEvents(tableRef);
  addDragEvents(tableRef, filterLayout, setIsUserConfigChanged);
};

const handleDragStart = (e, div) => {
  e.dataTransfer.setData('text/plain', div.getAttribute('data-index'));
};

const handleDragOver = (e) => {
  e.preventDefault();
};

const handleDrop = (e, filterLayout, setIsUserConfigChanged, div) => {
  e.preventDefault();
  const draggedIndex = e.dataTransfer.getData('text/plain');
  const targetElement = div.closest('.ag-filter-toolpanel-group-wrapper');
  if (!targetElement) return;
  const parentElement = targetElement.closest('.ag-filter-list-panel');
  if (!parentElement) return;
  const draggedElement = parentElement.querySelector(
    `[data-index='${draggedIndex}']`
  );

  if (draggedElement && draggedElement !== targetElement) {
    const rect = targetElement.getBoundingClientRect();
    const offset = e.clientY - rect.top;

    if (offset < rect.height / 2) {
      parentElement.insertBefore(draggedElement, targetElement);
    } else {
      if (targetElement.nextSibling) {
        parentElement.insertBefore(draggedElement, targetElement.nextSibling);
      } else {
        parentElement.appendChild(draggedElement);
      }
    }

    const updatedDivs = parentElement.querySelectorAll(
      '.ag-filter-toolpanel-group-wrapper'
    );
    const newPositions = [...updatedDivs].map((d) =>
      d.getAttribute('data-index')
    );
    filterLayout.current = rearrangeArray(filterLayout.current, newPositions);
    setIsUserConfigChanged(true);
    updatedDivs.forEach((div, index) => {
      div.setAttribute('data-index', index);
    });
  }
};

const rearrangeArray = (array, newPositions) => {
  if (array.length !== newPositions.length) {
    return array;
  }
  const rearrangedArray = new Array(array.length);
  for (let i = 0; i < newPositions.length; i++) {
    rearrangedArray[i] = array[newPositions[i]];
  }
  return rearrangedArray;
};
