export default function PriorityLabel({
  textColor,
  bgColor,
  text,
  heightClass = 'h-[20px]',
  widthClass = 'w-[54px]',
  fontSize = 'text-[10px]',
}) {
  let classList = `${textColor} ${bgColor} ${heightClass} ${widthClass} ${fontSize} uppercase rounded-sm text-center pt-[1px] font-medium`;
  return <div className={classList}>{text}</div>;
}
