import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loader from '../common/Loader';
import OutlineButton from '../common/OutlineButton';
import {
  SORT_ASCENDING,
  SORT_DESCENDING,
  USER_TABLE_COLUMNS,
} from '../../constants/constants';
import { ROLE_NAMES } from '../../constants/roles';
import EditUserForm from './forms/EditUserForm';
import { changeUserStatus, getUsers } from '../../services/userServices';
import ToggleButton from '../common/ToggleButton';
import FormModal from '../common/FormModal';
import ColoredButton from '../common/ColoredButton';
import OutlineIconButton from '../common/OutlineIconButton';
import clearSortIcon from '../../assets/icons/clear-sort.svg';
import { ErrorContext } from '../../ErrorContext';
import { refreshPage } from '../../helpers/commonHelper';

const sortUsers = (a, b, sortConfigurations) => {
  for (const config of sortConfigurations) {
    const aValue = a[config.column];
    const bValue = b[config.column];

    if (config.column === 'id') {
      if (aValue < bValue) return config.direction === SORT_ASCENDING ? -1 : 1;
      if (aValue > bValue) return config.direction === SORT_ASCENDING ? 1 : -1;
    }
    if (config.column === 'active') {
      if (aValue > bValue) return config.direction === SORT_ASCENDING ? -1 : 1;
      if (aValue < bValue) return config.direction === SORT_ASCENDING ? 1 : -1;
    }
    if (aValue.toString().localeCompare(bValue) === -1)
      return config.direction === SORT_ASCENDING ? -1 : 1;
    if (aValue.toString().localeCompare(bValue) === 1)
      return config.direction === SORT_ASCENDING ? 1 : -1;
  }
  return 0;
};

export default function AllUsers() {
  const { errorAlert, setErrorAlert } = useContext(ErrorContext);
  const { userId } = useSelector((state) => state.user);
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [displayedUsersList, setDisplayedUsersList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfigurations, setSortConfigurations] = useState([]);
  const [newUserOpen, setNewUserOpen] = useState(false);
  const [editProfileOpen, setEditProfileOpen] = useState(false);
  const [editUserInfo, setEditUserInfo] = useState(null);

  useEffect(() => {
    setIsLoaderVisible(true);
    getUsers()
      .then((users) => {
        setMappedUserList(users.data);
      })
      .catch((error) => {
        setErrorAlert({
          error: error,
          onButtonClick: refreshPage,
          buttonText: 'Try reloading',
          closingButton: true,
        });
      })
      .finally(() => {
        setIsLoaderVisible(false);
      });
  }, []);

  useEffect(() => {
    setFilteredData(searchQuery);
  }, [usersList]);

  const setMappedUserList = (users) => {
    const mappedUsers = users
      .sort((u1, u2) => u1.email.localeCompare(u2.email))
      .map((u) => ({
        ...u,
        full_name: getFullName(u.first_name, u.last_name),
      }));
    setUsersList(mappedUsers);
  };

  const currentUser = (user) => {
    return userId === user.id;
  };

  const getFullName = (firstName, lastName) => {
    return `${firstName} ${lastName}`;
  };

  function onUserAdd(data) {
    data.full_name = getFullName(data.first_name, data.last_name);
    setUsersList([data, ...usersList]);
    setSearchQuery('');
    setSortConfigurations([]);
  }

  function onUserUpdate(data) {
    data.full_name = getFullName(data.first_name, data.last_name);
    setUsersList(
      usersList.map((user) => {
        return user.id === data.id ? data : user;
      })
    );
  }

  const toggleUserStatus = (userId, active) => {
    changeUserStatus(userId, !active)
      .then(({ data }) => {
        onUserUpdate(data);
      })
      .catch((error) => {
        setErrorAlert({
          error: error,
          buttonText: null,
          closingButton: false,
        });
      });
  };

  const setFilteredData = (query) => {
    const words = query.split(' ').filter((word) => word.trim() !== '');
    const filteredData = usersList.filter((item) => {
      return words.every((word) =>
        USER_TABLE_COLUMNS.some(({ field }) => {
          const value = item[field];

          if (field === 'active') {
            const activeString = value ? 'active' : 'inactive';
            return activeString.toString().toLowerCase().includes(word);
          }
          return value.toString().toLowerCase().includes(word);
        })
      );
    });
    setDisplayedUsersList(filteredData);
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredData(query);
  };

  const handleSort = (column) => {
    let newSortConfigurations = [...sortConfigurations];
    const existingConfig = newSortConfigurations.find(
      (config) => config.column === column
    );

    if (existingConfig) {
      newSortConfigurations = newSortConfigurations.filter(
        (config) => config.column !== column
      );
      if (existingConfig.direction === SORT_ASCENDING) {
        newSortConfigurations.push({
          column: column,
          direction: SORT_DESCENDING,
        });
      }
    } else {
      newSortConfigurations.push({ column: column, direction: SORT_ASCENDING });
    }

    setSortConfigurations(newSortConfigurations);
  };

  const SortIndicator = ({ column }) => {
    const existingConfigIndex = sortConfigurations.findIndex(
      (config) => config.column === column
    );

    return (
      <>
        <div
          className={`ml-1.5${
            sortConfigurations.length < 2 || existingConfigIndex === -1
              ? ' invisible'
              : ''
          }`}
        >
          {existingConfigIndex + 1}
        </div>
        <div
          className={`material-symbols-outlined text-lg leading-none${
            existingConfigIndex === -1 ? ' invisible' : ''
          }`}
        >
          {sortConfigurations[existingConfigIndex]?.direction === SORT_ASCENDING
            ? 'arrow_upward'
            : 'arrow_downward'}
        </div>
      </>
    );
  };

  return (
    <>
      {isLoaderVisible && !errorAlert && <Loader />}
      {!isLoaderVisible && !errorAlert && (
        <div className="m-6">
          <div className="flex items-center justify-between mb-2">
            <input
              id="filter-text-box"
              type="text"
              placeholder="Search by any field..."
              className="max-w-xs mr-4"
              value={searchQuery}
              onChange={handleSearch}
            />
            {sortConfigurations.length > 0 && (
              <OutlineIconButton
                text="Clear Sort"
                outlineColor="border-gray-vs"
                bgClass="bg-white"
                padding="px-2 py-1"
                margin="mx-5"
                icon={clearSortIcon}
                widthClass="w-[110px]"
                onClick={() => setSortConfigurations([])}
              />
            )}
            <div className="ml-auto">
              <ColoredButton
                padding="px-1"
                text="New User"
                widthClass="w-[100px]"
                onClick={() => setNewUserOpen(true)}
              />
            </div>
          </div>
          <div className="table border-collapse table-auto w-full text-sm">
            <div className="table-header-group">
              <div className="table-row border-b-2 text-15 font-medium text-purple-vs-hover">
                {USER_TABLE_COLUMNS.map((column) => (
                  <div
                    key={column.field}
                    onClick={() => handleSort(column.field)}
                    className="table-cell p-2 cursor-pointer"
                  >
                    <div className="inline-flex">
                      <div>{column.name}</div>
                      <SortIndicator column={column.field} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="table-row-group text-[13px] font-light">
              {[...displayedUsersList]
                .sort((a, b) => sortUsers(a, b, sortConfigurations))
                .map((user) => (
                  <div
                    role="row"
                    key={user.id}
                    className="table-row border-b-2 h-12"
                  >
                    <div className="config-table-cell">{user.id}</div>
                    <div className="config-table-cell">{user.full_name}</div>
                    <div className="config-table-cell">{user.display_name}</div>
                    <div className="config-table-cell">{user.email}</div>
                    <div className="config-table-cell">
                      {ROLE_NAMES[user.role]}
                    </div>
                    {!currentUser(user) && (
                      <div className="config-table-cell">
                        {user.active ? (
                          <ToggleButton
                            onClick={() => {
                              toggleUserStatus(user.id, user.active);
                            }}
                            text="Active"
                          />
                        ) : (
                          <ToggleButton
                            onClick={() => {
                              toggleUserStatus(user.id, user.active);
                            }}
                            fillColorClass="bg-red-200"
                            textColor="text-red"
                            borderColorClass="border-red-200"
                            hoverColorClass="hover:bg-red-300"
                            text="Inactive"
                          />
                        )}
                        <div className="w-4 inline-block"></div>
                        <OutlineButton
                          onClick={() => {
                            setEditUserInfo(user);
                            setEditProfileOpen(true);
                          }}
                          outlineColor="border-purple-vs-hover"
                          textColor="text-purple-vs-hover"
                          bgClass="bg-white"
                          text="Edit User"
                        />
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
      <FormModal
        openState={newUserOpen}
        onClose={() => setNewUserOpen(false)}
        title="New User"
        form={
          <EditUserForm
            onCancel={() => setNewUserOpen(false)}
            onUserUpdate={onUserAdd}
            user={{}}
          />
        }
      />
      <FormModal
        openState={editProfileOpen}
        onClose={() => setEditProfileOpen(false)}
        title="Edit User"
        form={
          <EditUserForm
            onCancel={() => setEditProfileOpen(false)}
            onUserUpdate={onUserUpdate}
            user={editUserInfo}
          />
        }
      />
    </>
  );
}
