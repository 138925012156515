import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import OutlineButton from '../../common/OutlineButton';
import ColoredButton from '../../common/ColoredButton';
import { useSelector } from 'react-redux';
import { review_status } from '../../../constants/constants';

export default function UpdateReviewStatusModal({ onCancel, onSubmitHandler }) {
  const { buttonsDisabled, serverError } = useSelector((state) => state.modal);

  const onSubmit = (data) => {
    onSubmitHandler({
      review_status: data.reviewStatus.id,
      batch_action: 'batch review_status update',
    });
  };

  const { control, handleSubmit } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div translate="no" className={'text-13 my-6 px-1'}>
        <Controller
          name="reviewStatus"
          control={control}
          rules={{ required: true }}
          defaultValue={review_status.forSelect[0]}
          render={({ field }) => (
            <Select
              {...field}
              isDisabled={buttonsDisabled}
              options={review_status.forSelect}
            />
          )}
        />
      </div>
      <p className="text-red-500 text-center h-8 w-60 my-1 text-xs">
        {serverError}
      </p>
      <OutlineButton
        text="Cancel"
        heightClass="h-10"
        onClick={onCancel}
        disabled={buttonsDisabled}
      />
      <ColoredButton
        text="OK"
        heightClass="h-10"
        submit={true}
        disabled={buttonsDisabled}
      />
    </form>
  );
}
