import { ROLE_NAMES_LIST } from '../constants/roles';
import { AUTOMAPPER_ID, HIDDEN_MAPPED_BY_ID } from '../constants/constants';

export function formSelectStyle(errors, field = '') {
  return {
    control: (base, state) => ({
      ...base,
      borderWidth: '1px',
      borderStyle: 'solid',
      boxShadow: 'none',
      '&:hover': {
        borderColor: errors && errors[field] ? '1px solid red' : 'gray',
      },
      border: errors && errors[field] ? '1px solid red' : '1px solid lightgray',
      borderColor: state.isFocused ? 'hsl(0, 0%, 70%)' : 'rgb(223, 223, 223)',
    }),
  };
}

export function getMappingSelectElements(items) {
  return items.map((item) => {
    return {
      value: item.name,
      label: item.name,
      id: item.id,
      mappable: item.mappable,
    };
  });
}

export function getMappableElements(items) {
  return items.filter((v) => v.mappable);
}

export function getRolesSelectElements() {
  return ROLE_NAMES_LIST.map((role) => {
    return { value: role.id, label: role.name };
  });
}

export function getPracticeSelectElements(practices) {
  return practices
    .sort((p1, p2) => {
      if (parseInt(p1.id) > parseInt(p2.id)) return 1;
      if (parseInt(p1.id) < parseInt(p2.id)) return -1;
      else return 0;
    })
    .map((p) => {
      return {
        value: `${p.name}`,
        label: `[${p.id}] ${p.name}`,
        id: p.id,
        groups: p.groups,
      };
    });
}

function getGroupLabel(group) {
  return `[${group.id}] ${group.name}${
    group.descendants_ids.length > 0 ? ` (${group.descendants_ids.length})` : ''
  }`;
}

export function getGroupSelectElements(groups) {
  return groups.map((group) => {
    return {
      value: group.name,
      label: getGroupLabel(group),
      id: group.id,
      descendants_ids: group.descendants_ids,
    };
  });
}

export function getUserSelectElements(items) {
  return [{ value: 'Auto', label: 'Auto', id: AUTOMAPPER_ID }].concat(
    items.map((item) => {
      return {
        value: item.display_name,
        label: item.display_name,
        id: item.id,
      };
    })
  );
}

export function getUserElementsWithoutAutomapper(items) {
  return items.map((item) => {
    return {
      value: item.display_name,
      label: item.display_name,
      id: item.id,
    };
  });
}

export function getUserSelectElementsForMapper(userId) {
  return [
    { value: 'Me', label: 'Me', id: userId },
    { value: 'Auto', label: 'Auto', id: AUTOMAPPER_ID },
    { value: 'Other', label: 'Other', id: HIDDEN_MAPPED_BY_ID },
  ];
}

export function getActiveUserElements(items) {
  return items
    .filter((item) => item.active)
    .map((item) => {
      return {
        value: item.display_name,
        label: item.display_name,
        role: item.role,
        id: item.id,
      };
    });
}

export function getFilterSelectElements(items) {
  return items.map((item) => {
    return {
      value: item.label,
      label: `[${item.id}] ${item.label}`,
      id: item.id,
    };
  });
}

export function getPipelinestatusesForSelect(items) {
  return items.map((item) => {
    return {
      value: item.status_name,
      label: item.status_name,
      id: item.id,
    };
  });
}

export function selectFilter(candidate, input) {
  return (
    candidate.value.toUpperCase().startsWith(input.toUpperCase()) ||
    candidate.value.toUpperCase().includes(input.toUpperCase()) ||
    candidate.id?.toString() === input ||
    candidate.data?.id.toString() === input
  );
}
