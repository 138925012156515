import { memo } from 'react';

const UndoActions = memo(function UndoActions({
  undoAvailable,
  redoAvailable,
  undoAction,
  redoAction,
  undoActionShortcut,
  redoActionShorcut,
}) {
  return (
    <div className="flex">
      <div className="group relative">
        <div
          className={`ml-2 undo-button-${
            undoAvailable ? 'active' : 'inactive'
          }`}
          onClick={() => {
            undoAvailable && undoAction();
          }}
        />

        <div className="status-tooltip">
          <div className="status-tooltip-arrow" />
          <div className="font-semibold">{undoActionShortcut}</div>
        </div>
      </div>
      <div className="group relative">
        <div
          className={`ml-2 redo-button-${
            redoAvailable ? 'active' : 'inactive'
          }`}
          onClick={() => {
            redoAvailable && redoAction();
          }}
        />
        <div className="status-tooltip">
          <div className="status-tooltip-arrow" />
          <div className="font-semibold">{redoActionShorcut}</div>
        </div>
      </div>
    </div>
  );
});

export default UndoActions;
