import { useRef, useState } from 'react';
import ColoredButton from '../../common/ColoredButton';
import { useSelector } from 'react-redux';
import { updateUser } from '../../../services/userServices';
import OutlineButton from '../../common/OutlineButton';
import { MIN_PASSWORD_LENGTH } from '../../../constants/constants';
import { logger } from '../../../services/logger';
import { parseError } from '../../../helpers/errorHelper';
import { defaultError } from '../../../constants/errorConstants';

export default function ResetPasswordForm({ onCancel }) {
  const [error, setError] = useState(null);
  const { userId } = useSelector((state) => state.user);
  const newPassword = useRef(null);
  const newPasswordConfirm = useRef(null);
  const formLabelPeerClasses = `peer-placeholder-shown:translate-y-0
    peer-focus:scale-75
    peer-focus:-translate-y-3
    peer-placeholder-shown:scale-100`;

  const handleSubmitResetPassword = () => {
    let updateParams = {
      password: newPassword.current.value,
      password_confirmation: newPasswordConfirm.current.value,
    };
    updateUser(userId, updateParams)
      .then(() => onCancel())
      .catch((error) => {
        if (error.response) {
          setError(parseError(error).description);
        } else {
          logger.catchError(error);
          setError(defaultError.description);
        }
      });
  };

  return (
    <>
      <div className="relative mt-3">
        <input
          placeholder=" "
          id="password"
          ref={newPassword}
          type="password"
          className="formInput peer"
          onBlur={(e) => {
            if (e.target.value && e.target.value.length < MIN_PASSWORD_LENGTH) {
              setError(
                `Password must have at least ${MIN_PASSWORD_LENGTH} characters`
              );
            }
          }}
          onChange={(e) => {
            if (
              newPasswordConfirm.current &&
              newPasswordConfirm.current.value
            ) {
              if (e.target.value.length >= 8) {
                newPasswordConfirm.current.value === e.target.value
                  ? setError(null)
                  : setError('Passwords do not match');
              }
            } else if (e.target.value.length >= MIN_PASSWORD_LENGTH)
              setError(null);
          }}
        />
        <label
          htmlFor="password"
          className={`formLabel ${formLabelPeerClasses}`}
        >
          New password
        </label>
      </div>
      <div className="relative mt-3">
        <input
          placeholder=" "
          id="password_confirm"
          ref={newPasswordConfirm}
          type="password"
          className="formInput peer"
          onChange={(e) => {
            if (
              newPassword.current &&
              newPassword.current.value.length < MIN_PASSWORD_LENGTH
            )
              setError(
                `Password must have at least ${MIN_PASSWORD_LENGTH} characters`
              );
            else if (
              newPassword.current &&
              newPassword.current.value !== e.target.value
            )
              setError('Passwords do not match');
            else if (
              newPassword.current &&
              newPassword.current.value === e.target.value
            )
              setError(null);
          }}
        />
        <label
          htmlFor="password_confirm"
          className={`formLabel ${formLabelPeerClasses}`}
        >
          Confirm New Password
        </label>
      </div>
      <div className="h-4 w-full mb-4 text-center text-red-500 text-xs">
        {error}
      </div>
      <div className="flex-1 text-center mb-2">
        <OutlineButton
          outlineColor="border-gray"
          text="Cancel"
          widthClass="w-[120px]"
          heightClass="h-10"
          onClick={() => {
            setError(null);
            onCancel();
          }}
        />
        <ColoredButton
          fillColor="bg-vs-green"
          text="Save"
          widthClass="w-[120px]"
          heightClass="h-10"
          onClick={() => {
            if (!error) handleSubmitResetPassword();
          }}
        />
      </div>
    </>
  );
}
