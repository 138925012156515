export default function ColoredButton({
  fillColorClass = 'bg-vs-green',
  hoverColorClass = 'hover:bg-vs-green-hover',
  widthClass = 'w-[120px]',
  heightClass = 'h-[30px]',
  textColor = 'text-white',
  font = 'text-13',
  onClick,
  submit,
  text,
  disabled,
  border = 'border-solid border border-vs-green',
  padding = 'py-1 px-3',
  toolTipText = null,
  additionalClasses = null,
}) {
  let classList = `mx-1 rounded-sm
            ${padding}
            ${widthClass}
            ${textColor}
            ${font}
            ${heightClass}
            ${
              disabled
                ? 'bg-gray-350'
                : `${fillColorClass} ${hoverColorClass} ${border}`
            }
            ${additionalClasses}`;
  return (
    <>
      {onClick && (
        <button
          className={classList}
          onClick={onClick}
          disabled={disabled ? disabled : false}
          title={toolTipText}
        >
          {text}
        </button>
      )}
      {submit && (
        <button
          className={classList}
          type="submit"
          disabled={disabled ? disabled : false}
        >
          {text}
        </button>
      )}
    </>
  );
}
