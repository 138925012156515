import { useEffect, useRef } from 'react';
import { Dialog } from '@headlessui/react';
import Draggable from 'react-draggable';

export default function BatchActionsModal({
  isOpen,
  onClose,
  title,
  form,
  filtersPanelOpen,
}) {
  const nodeRef = useRef();
  useEffect(() => {
    return () => onClose();
  }, []);
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div
        className={`fixed inset-0 overflow-y-auto ${
          filtersPanelOpen ? 'mr-[285px]' : 'mr-[30px]'
        } `}
      >
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Draggable nodeRef={nodeRef}>
            <div ref={nodeRef}>
              <Dialog.Panel
                ref={nodeRef}
                className="max-w-md transform rounded-2xl bg-white px-14 py-10 text-left align-middle shadow-xl transition-all"
              >
                <Dialog.Title
                  as="h3"
                  className="text-center text-lg font-medium leading-6 font-bold text"
                >
                  {title}
                </Dialog.Title>
                <div className="my-4 text-center">{form}</div>
              </Dialog.Panel>
            </div>
          </Draggable>
        </div>
      </div>
    </Dialog>
  );
}
