import { forwardRef, useImperativeHandle, useState } from 'react';
import { ResetFilterButton } from './ResetFilterButton';
import { applyFiltersOnKeyPress } from '../../../helpers/tableFiltersHelper';

export default forwardRef((props, ref) => {
  const defaultValue = () => {
    return props.colDef.filterParams && props.colDef.filterParams.defaultValue
      ? props.colDef.filterParams.defaultValue
      : null;
  };
  const [filterValue, setFilterValue] = useState(defaultValue());

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        const { api, colDef, column, columnApi, context } = props;
        const { node } = params;

        const value = props.valueGetter({
          api,
          colDef,
          column,
          columnApi,
          context,
          data: node.data,
          getValue: (field) => node.data[field],
          node,
        });

        return filterPass(value, filterValue);
      },

      isFilterActive() {
        return filterValue !== null && filterValue !== '';
      },

      getModel() {
        if (!this.isFilterActive()) {
          return null;
        }

        return {
          value: filterValue,
          type: 'radio',
          label: props.colDef.headerName,
        };
      },

      setModel(model) {
        setFilterValue(
          model === null || model === undefined ? null : model.value
        );
      },
    };
  });

  const filterPass = (value, filterValue) => {
    return value === (filterValue === 'Yes');
  };

  const resetFilter = () => {
    if (filterValue) {
      setFilterValue(null);
    }
  };

  const Radio = ({ value }) => {
    return (
      <div className="flex-auto">
        <input
          type="radio"
          value={value}
          autoFocus={filterValue === value}
          checked={filterValue === value}
          onChange={(e) => setFilterValue(e.target.value)}
        />
        <span className="ml-2">{value}</span>
      </div>
    );
  };

  return (
    <div
      id="table-radio-filter"
      onKeyDown={({ key }) => applyFiltersOnKeyPress(key, props)}
    >
      <ResetFilterButton onReset={resetFilter} />
      <div className="flex p-2.5">
        <Radio value="Yes" />
        <Radio value="No" />
      </div>
    </div>
  );
});
